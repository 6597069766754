@import 'fonts';

.ui-level2-page-wrapper {
  box-sizing: border-box;
  width: 100%;
}

.ui-level2-page {
  padding: 24px;
  //height: 10px !important;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    color: var(--text-title);
  }
}


.ui-page.content-wrapper {
  padding: 32px 40px;

  .table-wrapper {
    margin-top: 16px;
    background: var(--elevation-surface);
    border: 1px solid var(--color-border-default);
    border-radius: 4px;

    .header {
      background: var(--color-elevation-surface-subtle);
      box-shadow: var(--box-shadow-default);

      div {
        padding: 12px 16px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        color: var(--color-text-subtle);
      }
    }

    .table-row {

      .td {
        padding: 0 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--color-text-default);
        height: 72px;
        box-shadow: var(--box-shadow-default);
      }

      .td.name {
        cursor: pointer;
      }

    }

  }

  .clickable-menu {
    cursor: pointer;
  }
}

