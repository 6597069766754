/* You can add global styles to this file, and also import other style files */
//@import "~bootstrap/dist/css/bootstrap.css";
//@import '~bootstrap/scss/bootstrap-reboot';
//@import '~bootstrap/scss/bootstrap-grid';
// regular style toast
@import 'ngx-toastr/toastr';
@import 'fonts';


input, textarea {
  border: 1px solid var(--input-border-default);
  background: var(--color-background-input-default);
  color: var(--color-text-subtlest);
}


/* Color definition */
$primary: #4367f6;
$secondary: #3456e0;
$menu-bg: #f7f8fc;
$menu-text: #101828;
$header-height: 74px;
$sidenav-width: 250px;
$sidenav-width-icons: 120px;
$sidenav-width-icons-narrow: 60px;
$gray: #b3b7c5;
$gray1: #344054;
$light-gray: #f9fafb;
$light-blue: #dae1ff;
$blue-text: #1b3fce;

html,
body {
  height: 100%;
  font-family: 'Satoshi' !important;
  color: var(--color-text-default);
}

body {
  margin: 0;
  //font-family: 'Inter', Roboto, "Helvetica Neue", sans-serif;
  font-family: 'Satoshi' !important;
  overflow: hidden;
}


.mat-tab-body {
  padding: 20px !important;
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}


.mat-table {
  background-color: transparent !important;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}

table tr:last-child td /*To remove the last border*/
{
  //border-bottom: 0 solid;
}

table tr {
  transition: 0.3s;
}

.plyr {
  height: 100%;
  width: 100%;
}

.plyr__video-wrapper {
  height: 100%;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: none !important;
}

.plyr__video-wrapper iframe {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: 'Satoshi';
}

.page-wrapper {
  height: calc(100%);
  width: 100%;
  font-family: 'Satoshi';

  > div {
    width: 100%;
    height: 100%;
  }

  .inner {
    //max-width: 1820px;
    padding: 25px 30px;
    overflow-y: auto;
    max-height: calc(100vh - 75px);
  }
}

.footer-actions {
  height: 70px;
  max-height: 70px;
  padding: 15px 28px;
  background: var(--elevation-surface);
  border-top: 1px solid var(--color-border-default);
}

.c-primary {
  color: var(--color-primary-500);;
}

.c-green {
  color: #22C55E;
}

.c-gray {
  color: #667085;
}

.c-red {
  color: #d67979;
}

.c-blue {
  color: #748fffff;
}

.c-white {
  color: #ffffff;
}

.c-yellow {
  color: #ffdd6e !important;
}

.c-orange {
  color: #F59E0B !important;
}

.bold {
  font-weight: bold;
}

.mat-dialog-content {
  max-height: 80vh !important;
}

.table-style-1 {
  .mat-table thead {
    background: #f9fafb;
    color: #777777;
  }

  .mat-table thead tr:hover {
    background: #f9fafb !important;
  }

  .mat-cell,
  th.mat-header-cell {
    padding: 13px;
    font-size: 14px;
  }
}


.menu {
  .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0px 33px;
  }

  &.loaded {
    .mat-list-base .mat-list-item .mat-list-item-content, {
      transition: 0.5s;
    }
  }

  .dropdown-arrow {
    transition: 0.5s;
    position: absolute;
    left: 5px;
  }

  .items a.active {
    //background: rgba(67, 103, 246, 0.08);
    background: #f4f6fe;
    color: var(--color-primary-500);;

    .mat-icon {
      color: var(--color-primary-500);;
    }

    .mat-icon svg path {
      fill: var(--color-primary-500);;
    }
  }
}

.svg-fill-primary, .ui-icon-primary {
  .mat-icon svg path {
    fill: var(--color-primary-500);
  }

  span:before {
    color: var(--color-primary-500);
  }
}

.svg-fill-white, .ui-icon-white {
  .mat-icon svg path {
    fill: #FFFFFF;
  }

  span:before {
    color: var(--elevation-surface);
  }
}

.svg-fill-red, .ui-icon-red {
  .mat-icon svg path {
    fill: var(--color-red-500);
  }

  span:before {
    color: var(--color-red-500);;
  }
}

.svg-fill-yellow, .ui-icon-yellow {
  .mat-icon svg path {
    fill: #F59E0B;
  }

  span:before {
    color: #F59E0B;
  }
}

.svg-fill-green, .ui-icon-green {
  .mat-icon svg path {
    fill: var(--color-secondary-green-500);
  }

  span:before {
    color: var(--color-secondary-green-500);
  }
}


.svg-fill-gray {
  .mat-icon svg path {
    fill: #7a88b1;
  }
}

.svg-fill-gray-600 {
  .mat-icon svg path {
    fill: var(--color-secondary-gray-600);
  }
}

.sidenav-wrapper:not(.opened) {
  .mat-list-item-name {
    opacity: 1;
  }

  .menu {
    .mat-list-base .mat-list-item,
    .mat-list-base .mat-list-option {
      height: 64px !important;
      width: 100% !important;

      &.narrow {
        height: 36px !important;
      }
    }

    .mat-list-base .mat-list-item .mat-list-item-content {
      //padding: 0px 16px;
    }

    .dropdown-arrow {
      transition: 0.5s;
      left: -5px !important;
      opacity: 0;
    }

    .items a {
      .mat-icon svg {
        width: 24px;
      }
    }
  }
}

.fadeInfadeOut {
  opacity: 1 !important;
  animation: fade 1.5s linear !important;
  animation-iteration-count: infinite !important;
}

.breadcrumb-small {
  font-size: 10px;
  color: #667085;
}

.plyr--video {
  overflow: hidden;
  border-radius: 4px !important;
}

.menu-button {
  background: $light-gray;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
}

.icon-button {
  border: 1px solid #d5dbee !important;
  border-radius: 8px !important;
  height: 48px !important;
  width: 48px !important;
  color: #b3b7c5 !important;
}


.collection {
  &-top-filter-wrapper {
    background: #ffffff;
    padding: 20px 20px;
  }
}

.infinite-scroll-container {
  margin: 30px 20px;
  height: 76vh;
  width: 98%;
  overflow: auto;
}

.wall-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 75px);
}

.onvif-compliant {
  opacity: 0.2;

  img {
    max-width: 55px;
  }
}

.onvif-compliant.true {
  opacity: 1;
}

@media only screen and (max-height: 1050px) {
  .infinite-scroll-container {
    height: 70vh;
  }
}

@media only screen and (max-width: 1300px) {
  .infinite-scroll-container {
    width: 97%;
  }
}

@media only screen and (max-width: 1210px) {
  .infinite-scroll-container {
    width: 95%;
    height: 68vh;
  }
}

.mat-dialog-container {
  border-radius: 5px !important;
  box-shadow: 0px 4px 3px -7px rgb(0 0 0 / 10%), 0px 3px 11px 3px rgb(0 0 0 / 6%), 0px 3px 17px 8px rgb(0 0 0 / 5%) !important;
  height: auto !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.15) !important;
}

.mat-list-base {
  padding-top: 0px !important;
}


.location-label {
  padding: 0px;
  margin: 0px;
}

.location-address {
  color: var(--color-primary-500);;

  .mat-icon {
    font-size: 12px;
    height: 12px;
    width: 12px;
  }
}

.camera-player-wrapper {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 5px;
  position: relative;
  background: #000000;
  width: 100%;

  &:hover {
    .camera-change-label {
      opacity: 1;
    }
  }

  .camera-change-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 999;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    padding: 10px 15px;
    opacity: 0;
    transition: 0.3s;
  }

  stream-player .p-wrapper .video-wrapper, webrtc-player .p-wrapper .video-wrapper {
    background: #000;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  stream-player .p-wrapper .video-wrapper video, webrtc-player .p-wrapper .video-wrapper video {
    border-radius: 0px;
    display: block;
    height: 100%;
    width: 100%;
  }
}

.playback-wrapper,
.camera-view-wrapper {
  stream-player .p-wrapper .video-wrapper, webrtc-player .p-wrapper .video-wrapper {
    background: #000;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 4px 4px 4px 4px;
  }

  stream-player .p-wrapper .video-wrapper video, webrtc-player .p-wrapper .video-wrapper video {
    border-radius: 4px 4px 4px 4px;
    height: 100%;
    width: 100%;
    background: #000;
  }
}

.no-camera-wrapper {
  border: 1px dashed var(--color-primary-500);;
  border-radius: 5px;
}

.font {
  &-w-500 {
    font-weight: 500;
  }

  &-16 {
    font-size: 16px;
  }

  &-12 {
    font-size: 12px;
  }
}

.primary {
  color: var(--color-primary-500);;
}

//todo remove
.camera-preview-wrapper {
  position: relative;
  background: rgba(213, 219, 238, 0.2);
  border: 1px dashed var(--color-primary-500);;
  border-radius: 4px;
  min-height: 352px;

  .image-loader {
    position: absolute;
    background: #f7f8fc;
    width: 100%;
    height: 100%;
  }

  ui-snapshot {
    height: 100% !important;
    width: 100% !important;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  &.loaded {
    border: none;
    background: none;

    ui-snapshot {
      img {
        background: #f7f8fc;
        border: 1px dashed var(--color-primary-500);;
        border-radius: 4px;
      }
    }
  }
}

.tabs {
  position: relative;

  .mat-tab-body {
    transition: 0.5s;
    opacity: 0;
    margin: 0px 20px;
  }

  .mat-tab-body.mat-tab-body-active {
    margin: 0px;
    opacity: 1;
  }

  .mat-tab-body-content {
    overflow: unset;
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-label {
    margin: 20px 10px;
    height: 34px;
    min-width: 0px;
    padding: 0 15px;
  }

  .mat-tab-label-active {
    background: #dae1ff;
    border-radius: 25px;
    opacity: 1;

    .mat-tab-label-content {
      color: var(--color-primary-500);;
    }
  }

  .mat-tab-body {
    padding: 15px 50px !important;

    mat-label {
      color: #475569;
    }
  }

  .mat-tab-body.mat-tab-body-active {
    overflow-y: overlay;
  }

  .mat-tab-header:hover .mat-tab-header-pagination {
    opacity: 1;
  }

  .mat-tab-header-pagination {
    height: 36px;
    border-radius: 50px;
    margin: 19px 10px;
    box-shadow: none;
    transition: ease-out 0.3s;
    opacity: 0;
    width: 0px !important;
    min-width: 27px;
    display: none;
    //box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 8%), 0px 1px 10px 0px rgb(0 0 0 / 8%);
  }

  .mat-tab-header-pagination-disabled {
    min-width: 10px;
    box-shadow: none;
    opacity: 0 !important;
  }

  .mat-ink-bar {
    display: none;
  }

  .form-wrapper {
    > div {
      width: 100%;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background: #d5dbee;
    margin: 20px 0px;
  }

  .section-wrapper {
    padding-top: 20px;
  }
}

.camera-view {
  position: relative;

  .tabs {
    padding: 0px 20px;

    .mat-tab-body {
      padding: 0px 0px 0px 0px !important;
    }

    .mat-tab-label-active {
      padding: 0px 25px;
      background: var(--color-primary-500);;
      border-radius: 25px;
      opacity: 1;

      .mat-tab-label-content {
        color: #ffffff;
      }
    }
  }

  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
  .mat-mdc-form-field-flex:hover
  .mat-mdc-form-field-outline-gap {
    border-top-color: #000000 !important;
    transition: 0.1s;
  }

  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-outline-gap {
    border-top-color: #e4e4e4 !important;
  }

  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float.mat-focused .mat-mdc-form-field-outline-gap {
    border-top-color: #4367f6 !important;
  }

  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label,
  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float
  .mat-input-server:focus
  + .mat-mdc-form-field-label-wrapper
  .mat-mdc-form-field-label {
    transform: translateY(-0.7em) scale(0.75);
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    //padding: 1.3em 0 0.7em 0;
    padding: 0.1em 4px 0.36em 0px;
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex {
    margin-top: -0.3em;
  }

  ui-datetime-range-picker {
    .date-range-wrapper.no-label {
      //margin: 0.25em 0;
    }
  }

  app-search-selector {
    margin-bottom: -18px;
  }

  app-search-selector .advanced-search-button {
    line-height: 29px;
    padding: 0px 19px;
  }
}

.picker-wrapper,
.playback-url {
  width: 100%;

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px;
  }
}

.on-top {
  z-index: 998;
}

.mw {
  width: 100% !important;
}

.mw-m {
  width: 100% !important;
}

@media only screen and (min-width: 2000px) {
  .mw {
    max-width: 1400px !important;
  }

  .mw-m {
    max-width: 1600px !important;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
  .mw {
    max-width: 1500px !important;
  }

  .mw-m {
    max-width: 1500px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .mw {
    max-width: 1100px !important;
  }

  .mw-m {
    max-width: 1300px !important;
  }
}

@media only screen and (max-height: 900px) {
  .mw {
    max-width: 1500px !important;
  }

  .mw-m {
    max-width: 1500px !important;
  }

  .ui-selector-panel {
    mat-dialog-container {
      //transform: scale(0.9) !important;
    }
  }
}

.mat-mdc-menu-panel {
  max-width: 100% !important;
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}

.toggle-button .mat-icon {
  line-height: 22px;
}

.controls-options {
  min-height: auto !important;
  font-size: 12px;

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.speed-options {
  background: rgba(0, 0, 0, 0.95) !important;
  min-height: auto !important;
  font-size: 12px;

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mat-mdc-menu-content {
    background: none !important;
  }

  .mat-mdc-menu-item {
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
  }

  .mat-icon {
    height: 14px;
    width: 14px;
    font-size: 12px;
    color: #ffffff !important;
    margin-right: 5px !important;
  }
}

.hidden {
  display: none !important;
}

.options-menu {
  font-size: 13px;
  padding: 8px 15px 0px 15px;
  place-content: start flex-start !important;
  align-items: start !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  display: flex !important;
  min-width: 450px;
  max-width: 500px;

  .label {
    font-weight: 500;
    border-bottom: 1px solid #d5dbee;
    width: 100%;
    padding-bottom: 8px;
  }

  &-item {
    padding: 5px 0px;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.advanced-search {
  padding: 0px;
  max-height: 75vh;
  min-height: 570px;

  > div {
    min-height: 570px !important;
  }

  .side-panel {
    padding: 10px 0px 10px 0px;
    border-right: #000;

    .subject {
      height: 40px;
      padding: 0px 10px 0px 25px;

      &.selected {
        background: #f4f6fe;
      }
    }

    .label {
      color: #667085;
      padding-left: 24px;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }

  .properties-placeholder {
    width: 100%;
    min-height: 570px;
    border-left: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
    background: #ffffff;
  }

  .properties-panel {
    overflow-y: auto;
    max-height: 75vh;
    min-height: 570px;
    background: #ffffff;
    border-bottom: 1px solid #eaecf0;
    border-left: 1px solid #eaecf0;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px;
    background: #ffffff;
  }

  .search-selector {
    width: 100%;

    .mat-mdc-form-field-wrapper {
      padding-bottom: 0px;
      background: #ffffff;
    }

    .mat-mdc-form-field-infix {
      width: auto;
    }
  }

  .operator {
    max-width: 58px !important;

    .mat-select-arrow {
      border: none;
    }
  }
}

.advanced-search .operator:hover {
  .mat-select-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px;
  }
}

.mat-drawer[style*='visibility: hidden'] {
  display: block !important;
}

.main-settings {
  h1 {
    margin-bottom: 0px;
  }

  label {
    font-weight: 600;
    color: #344054;
  }

  .tabs .mat-tab-body {
    padding: 15px 0px !important;

    mat-label {
      color: #475569;
    }
  }
}

.playback-timeline {
  padding: 20px;
  background: rgba(213, 219, 238, 0.2);
  border-radius: 4px;
  position: relative;
  min-height: 150px !important;

  .bar-line {
    background: #000000;
    width: 1px;
    height: 50%;

    &-wrapper {
      padding: 0px 17px;
      position: relative;
    }

    &-current {
      position: relative;
      width: 2px;
      background: #4367f6;
      height: 41%;
      top: 26%;
      cursor: pointer;
      z-index: 999;

      mat-icon {
        color: red;
        margin: -4px 0px 0px -11px;
      }
    }
  }

  .bar-line.full {
    height: 100%;
  }

  .bar-line-current-wrapper {
    position: absolute;
    top: 0px;
    left: 36px;
    z-index: 998;
    width: calc(100% - 72px);
    height: 100%;
  }

  .bar-line-current-tooltip {
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 5px;
    transition: 0.3s;
    margin-left: 3px;
    user-select: none;
  }

  .bar-line-current-tooltip.hide {
    opacity: 0;
  }
}

.cdk-overlay-container .cdk-overlay-pane .org-dropdown-panel {
  margin-top: 30px !important;

  .mat-option-button {
    text-align: center;
    border: 1px solid black;
    border-radius: 6px;
    margin: 4px;
    padding: 5px 0;
    cursor: pointer;
  }

  .done-icon {
    height: 20px;
  }
}

.playback-player {
  .plyr {
    max-height: 48vh;
  }

  @media only screen and (max-height: 1300px) {
    .plyr {
      max-height: 40vh;
    }
  }

  @media only screen and (max-height: 1000px) {
    .plyr {
      max-height: 36vh;
    }
  }

  @media only screen and (max-height: 830px) {
    .plyr {
      max-height: 30vh;
    }
  }
}

.invisible {
  opacity: 0;
  max-height: 0;
  height: 0;
  transition: 0.3s;
}

//.bar-line-download-area {
//  position: absolute;
//  top: 0px;
//  left: 36px;
//  z-index: 999;
//  width: 0px;
//  height: 100%;
//  background: rgba(50, 177, 255, 0.5);
//}

.bar-line-download-area {
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
  left: 36px;
  z-index: 999;
  width: 0px;
  height: 30%;
  background: rgba(67, 103, 246, 0.15);
  box-shadow: inset 0px 1px 0px 0px $primary, inset 15px 0px 0px 0px $primary, inset -15px 0px 0px 0px $primary,
  inset 0px -1px 0px 0px $primary;
  //border-left: 15px inset $primary;
  //border-right: 15px inset $primary;
  //border-bottom: 2px inset $primary;
  //border-top: 2px inset $primary;
  border-radius: 4px;

  &.no-border {
    border: none;
  }

  &.show:before {
    position: absolute;
    content: '||';
    color: #fff;
    font-size: 10px;
    left: 5px;
  }

  &.show:after {
    position: absolute;
    content: '||';
    color: #fff;
    font-size: 10px;
    right: 5px;
  }
}

.bar-selection-ts {
  position: absolute;
  top: 0px;
  background: rgba(50, 177, 255, 0.5);
  border-radius: 5px;
  padding: 5px;

  &.start {
    left: -70px;
  }

  &.end {
    right: -70px;
  }
}

.options-button {
  position: absolute;
  background: #ffffff;
  top: 10px;
  right: 30px;
  z-index: 999;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.tab-actions {
  position: absolute;
  background: #ffffff;
  top: 10px;
  right: 30px;
  z-index: 999;

  &-button {
    background: $light-gray !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 8px !important;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    font-size: 23px;
    padding: 0px 12px;

    mat-icon {
      color: #777777;
      height: 27px;
      width: 37px;
    }
  }

  &-menu-button {
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 8px !important;
  }
}

.camera-settings {
  .mat-slide-toggle-bar {
    height: 22px;
    width: 44px;
    border-radius: 2px;
  }

  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    height: 22px;
    background: #0acb9d;
  }

  .mat-slide-toggle-thumb-container {
    top: 2px;
    left: 2px;
  }

  .mat-slide-toggle-thumb {
    height: 18px;
    width: 19px;
    border-radius: 0px;
    box-shadow: none;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(21px, 0, 0);
  }

  .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background: #ffffff;
  }

  .toggle-label {
    font-size: 10px;
  }

  // todo Remove
  .camera-detail {
    border: 1px solid #cbd5e1;
    padding: 14px 16px;
    border-radius: 4px;
    background: #eeeeee63;

    label {
      color: #777777;
    }

    input {
      border: none;
      text-align: right;
      font-size: 16px;
      padding: 5px;
      border-radius: 5px;
      width: 50px;
    }

    input.wide {
      width: 200px;
    }

    &.edit {
      padding: 10px 16px;
      background: #fff;
    }
  }

  //
  .ptz .camera-detail {
    padding: 10px 16px;
  }
}

.edge-settings {
  @extend .camera-settings;
}

.settings-sidebar {
  position: fixed;
  top: 75px;
  right: 0px;
  bottom: 0px;
  width: 590px;
  height: calc(100% - 75px);

  border-left: 1px solid #d5dbee;
  transition: 0.5s;
  z-index: 999;
  background: #fff;
  transform: translateX(100%);
  resize: both;

  &.wide {
    width: 75vw;
  }
}

.settings-sidebar.opened {
  box-shadow: none;
  transform: translateX(0%);
  // broken in chrome
  //filter: drop-shadow(-75px 176px 77px rgba(0, 0, 0, 0.01)) drop-shadow(-42px 99px 65px rgba(0, 0, 0, 0.03)) drop-shadow(-19px 44px 48px rgba(0, 0, 0, 0.04)) drop-shadow(-5px 11px 26px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
}

.edit-edge-ip-wrapper {
  mat-progress-bar {
    opacity: 0.15;
    margin-top: -12px;
    height: 34px;
    margin-bottom: -21px;
  }
}

.scroll-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 74px);
}

.location-scroll-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 130px);
}

mat-icon.up {
  transform: rotateX(180deg);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 4%) !important;
}

//todo remove and make as typografy
.ui-tab {
  .page-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

//todo remove and make as typografy
.alert-tab,
.variable-tab {
  padding: 32px 32px;
  max-width: 1000px;

  .page-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 0px;
    color: #000000;
  }

  .info-icon {
    height: 14px;
    width: 14px;
    line-height: 14px;
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    margin: 0px 0px;
  }

  .attributes-block {
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    margin-top: 16px;

    .radio-button-wrapper {
      //padding: 16px 24px;
      background: #ffffff;
      box-shadow: inset 0px -1px 0px #eaecf0;
      cursor: pointer;
    }
  }
}

.modal-16-24 {
  mat-dialog-container {
    padding: 16px 24px !important;
  }
}

.modal-no-padding {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.color-selector {
  .mat-mdc-select-trigger .mat-mdc-select-value {
    margin-left: 31px !important;
  }
}

.radio-row {
  margin-left: 8px;
  cursor: pointer;

  .radio-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #344054;
  }

  .radio-description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #667085;
  }
}

.radio-button {
  padding: 0;
  margin-top: 5px;
  cursor: pointer;
}

.zone-tab, .ui-tab,
.alert-notification-user-selection table,
.alert-notification-manual-selection,
.zone-tab .cdk-drag-preview {
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 5px 0px 9px 0px;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.ui-chip-select {
  margin: 4px;

  .mat-chip.mat-standard-chip {
    padding: 7px 16px;
    cursor: pointer;
    background: var(--elevation-surface);
    border: 1px solid #d0d5dd;
    color: var(--color-text-default);

    mat-icon {
      font-size: 18px;
      color: #344054;
      height: 18px;
      width: 18px;
      line-height: 18px;
    }

    :focus {
      background: red;
    }
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: #f4f6fe;
    border: 1px solid var(--color-primary-500);;
    color: var(--color-primary-500);;

    mat-icon {
      color: var(--color-primary-500);;
    }
  }
}

.section-wrapper {
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #101828;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
  }
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 5%), 0px 8px 10px 1px rgb(0 0 0 / 3%), 0px 3px 14px 2px rgb(0 0 0 / 2%) !important;
}

.delete-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
}

.wall-max-width {
  max-width: calc(125vh) !important;
}

.multi-search-view {
  ui-datetime-range-picker .date-range-wrapper {
    padding: 14px 20px 14px 20px;
    border-radius: 5px;

    label {
      display: none;
    }
  }
}

.pin-img {
  height: 132px;
  width: 140px;
}

.logo {
  height: $header-height;
  min-height: $header-height + 1;
  min-width: $sidenav-width-icons;
  background: #f7f8fc;
  padding: 0px 38px;
  transition: 0s;

  &.fixed {
    border-right: 1px solid #d5dbee;
    border-bottom: 1px solid #d5dbee;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
  }

  mat-icon {
    color: #7c808e;
  }

  img.show {
    width: 153px;
    height: 43px;
  }

  &.closed {
    transition: 0.5s;
    padding: 0px 10px;
  }
}

#float-video {
  app-camera-status-overlay {
    .camera-overlay {
      padding: 3px !important;
    }

    z-index: 999;
  }
}

.top-left-actions {
  z-index: 999;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0px 8px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  color: #ffffff;
  border-radius: 5px;
  min-height: 32px;

  mat-icon {
    color: #ffffff;
    font-size: 30px;
    height: 30px;
    width: 30px;
    line-height: 40px;
  }

  .maximize-button {
    cursor: pointer;

    mat-icon {
      color: #ffffff;
      color: #ffffff;
      font-size: 35px;
      height: 35px;
      width: 30px;
      line-height: 43px;
    }
  }

  .divider {
    height: 28px;
    width: 2px;
    background: #d5dbee;
    margin: 0px 0px 0px 5px;
    opacity: 0.1;
  }
}

.monitoring-view-page {
  .ng-scroll-content {
    width: 100%;
  }
}

.search-properties-selector .operator-selector {
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 0.2em 0px 0.5em 0px;
  }
}

.quality-selector {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #ffffff;
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: unset;
}

.mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: var(--color-background-selected-selector-active);
  border-color: var(--color-border-selected-default);
}

.checking-connection-wrapper {
  padding: 3px 0px;

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #ffffff;
  }
}

.thumbnail-dialog-wrapper {
  .mat-dialog-container {
    padding: 0px;
  }

  app-events-histogram .rel {
    background: #ffffff;
    opacity: 1;
    border-radius: 10px 10px 0px 0px;
  }

  .multiple-histogram app-events-histogram .rel {
    border-radius: 0px;
    margin: 0px 0px 1px 0px;
  }
}

.playback-player.new-design {
  app-player-controls .controls .wrapper {
    padding: 0px !important;
    background: #fff;
    margin-top: 10px;

    button {
      background: $light-gray;
      border-radius: 4px;
      height: 32px;
      width: 32px;

      .mat-icon-button {
        line-height: 0px !important;
      }

      mat-icon {
        height: 15px !important;
        width: 14px !important;
      }
    }
  }

  stream-player, webrtc-player {
    .current-zoom-label {
      top: 15px;
      background: rgba(0, 0, 0, 0.5);
    }

    .manual-zoom-wrapper {
      bottom: 15px;
      right: 15px;
      background: none;
      backdrop-filter: none;
      border-radius: 0px;

      > div {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(50px);
        color: #ffffff;
        padding: 5px;
        border-radius: 4px;
      }
    }
  }

  app-playback-timeline {
    .playback-timeline {
      background: $light-gray;
    }
  }
}

.no-us {
  user-select: none;
}

.hover {
  opacity: 0;
  transition: 0.3s;
}

.p-wrapper:hover .hover,
.animation-wrapper:hover .hover,
.playback-wrapper:hover .hover,
.camera-view-wrapper:hover .hover {
  opacity: 1;
  transition: 0.3s;
}

.mat-slider-horizontal {
  height: 40px;
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 2px;
  border-radius: 10px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background {
  background-color: #ececec;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: var(--color-primary-500);;
}

.mat-slider-thumb {
  height: 30px !important;
  width: 30px !important;
  background-color: white !important;
  border: 1px solid var(--color-secondary-gray-400) !important;
  bottom: -15px !important;
  right: -20px !important;

  &:before {
    background: var(--color-primary-500);
    position: absolute;
    content: " ";
    top: 50%;
    left: 50%;
    height: 8px;
    width: 8px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

.camera-view.new-design {
  .view-wrapper {
    padding: 20px 0px 0px 0px;
  }

  .top-details {
    padding: 25px 0px 10px 0px;
  }

  .animation-wrapper {
    background: #fff;
    border-bottom: none;
  }

  .mat-tab-header {
    display: none;
  }
}

.bar {
  opacity: 0.5;
  transition: 0.3s;
}

.bar:hover {
  opacity: 1;
}

.bar-label {
  opacity: 0.4;
  font-size: 12px;
  width: 100%;
  margin-top: 10px;
}

.bar-tooltip {
  position: absolute;
  z-index: 999 !important;
  top: -78px;
  background: #fff;
  border-radius: 8px;
  padding: 11px 15px;
  transform: translateX(-50%);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15), 0 8px 10px 1px rgba(0, 0, 0, 0.11), 0 3px 14px 2px rgba(0, 0, 0, 0.09);
  min-width: 320px;

  &-time {
    font-size: 12px;
    margin-top: 5px;
    opacity: 0.5;
  }

  &-status {
    font-size: 16px;

    &.red {
      color: #f24949;
    }
  }
}

.menu.closed {
  .organization-dropdown {
    mat-select {
      width: 33px;
      border: 1px solid #d5dbee;
      border-radius: 8px;
      padding: 13px 7px;
      background: #ffffff;

      .mat-select-trigger .mat-select-arrow-wrapper {
        display: none;
      }
    }
  }

  .avatar-random-color {
    color: white;
    width: 50px !important;
  }
}

.display-none {
  display: none;
}

.success {
  color: #34d399;
}

.error {
  color: #f87171;
}

.filters-menu {
  font-size: 13px;
  padding: 8px 15px 0px 15px;
  place-content: start flex-start !important;
  align-items: start !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  display: flex !important;
  min-width: 350px;

  .label {
    font-weight: 500;
    border-bottom: 1px solid #d5dbee;
    width: 100%;
    padding-bottom: 8px;
  }
}

.success-snackbar {
  background: #f0fdf4 !important;
  white-space: pre-wrap;
  border: 1px solid #22c55e !important;
  border-left: 3px solid #22c55e !important;
  color: #000000 !important;
  white-space: pre-wrap;

  .mat-button-wrapper {
    font-family: 'Material Icons';
    font-size: 16px;
    line-height: 2.4;
    letter-spacing: normal;
    text-transform: none;
  }

  .mat-simple-snackbar-action {
    color: #667085;
  }

  .mat-snack-bar-container:hover {
    background: none !important;
  }
}

.failed-snackbar {
  background: #fef2f2 !important;
  border: 1px solid #ef4444 !important;
  border-left: 3px solid #ef4444 !important;
  color: #000000 !important;
  white-space: pre-wrap;

  .mat-button-wrapper {
    font-family: 'Material Icons';
    font-size: 16px;
    line-height: 2.4;
    letter-spacing: normal;
    text-transform: none;
  }

  .mat-simple-snackbar-action {
    color: #667085;
  }

  .mat-snack-bar-container:hover {
    background: none !important;
  }
}

.ptz {
  .clickable {
    user-select: none;
  }

  .clickable:active {
    .mat-icon svg path {
      fill: var(--color-primary-500);;
    }
  }
}

.section-title {
  font-weight: 500;
  font-size: 16px;
}

.narrow-input {
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    //padding: 1.3em 0 0.7em 0;
    padding: 6px 4px 10px 0px;
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex {
    margin-top: -0.3em;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px;
  }
}

.narrow-input-40 {
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 2px 4px 8px 0px !important;
  }

}

.narrower-input {
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    //padding: 1.3em 0 0.7em 0;
    padding: 5px 4px 10px 0px;
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex {
    margin-top: -0.3em;
  }


  .mat-mdc-form-field .mat-mdc-form-field-prefix .mat-icon {
    color: #667085;
    font-size: 16px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    margin-right: 10px;
  }
}


.tall-date-range-picker {
  .date-range-wrapper.no-label {
    padding: 5px 23px 5px 13px !important;
  }
}

//
//.playback-quality-selector {
//  .quality-selector {
//    position: absolute;
//    bottom: 20px !important;
//    left: 20px !important;
//    padding: 5px 10px;
//    background: rgba(0, 0, 0, 0.5) !important;
//    -webkit-backdrop-filter: blur(50px);
//    backdrop-filter: blur(50px);
//    border-radius: 4px;
//    color: #ffffff;
//    font-size: 14px;
//    z-index: 999;
//    width: 70px;
//  }
//}

.playback-quality-selector {
  position: absolute;
  bottom: 20px !important;
  left: 20px !important;
  z-index: 999;
}

.dx-mention {
  background: #e1e6fa !important;
  color: #2748cc !important;
  padding: 7px 7px;
  border-radius: 30px;
  line-height: 38px;
}

.workspace-create {
  .mat-expansion-panel-spacing {
    margin: 0px !important;
  }
}

.ui-camera-select.narrow {
  .location-cameras-list {
    height: 68px;

    .camera-block {
      padding: 12px 16px 12px 16px !important;

      .camera-snapshot {
        width: 42px !important;
        height: 33px !important;

        img {
          height: 33px !important;
        }
      }

      .camera-name {
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .radio {
      padding-right: 16px !important;
    }
  }
}

.icon-12 {
  .mat-icon {
    height: 12px !important;
    width: 12px !important;
    line-height: 12px !important;
    font-size: 12px !important;
  }
}

.icon-14 {
  .mat-icon {
    height: 14px !important;
    width: 14px !important;
    line-height: 14px !important;
    font-size: 14px !important;
  }
}

.icon-16 {
  .mat-icon {
    height: 16px !important;
    width: 16px !important;
    line-height: 16px !important;
    font-size: 16px !important;
  }
}

.icon-18 {
  .mat-icon {
    height: 18px !important;
    width: 18px !important;
    line-height: 18px !important;
    font-size: 18px !important;
  }
}

.icon-20 {
  .mat-icon {
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
    font-size: 20px !important;
  }
}

.icon-24 {
  .mat-icon {
    height: 24px !important;
    width: 24px !important;
    line-height: 24px !important;
    font-size: 24px !important;
  }
}

.widget-info-content {
  color: #667085;

  .mat-button {
    line-height: 32px !important;
  }
}

.error-card {
  padding: 15px 20px;
  background: #FEF2F2;
  border: 1px solid #EF4444;
  border-left: 3px solid #EF4444;
  border-radius: 4px;
  color: var(--color-red-700);
}

.success-card {
  padding: 15px 20px;
  background: #F0FDF4;
  border: 1px solid #22C55E;
  border-left: 3px solid #22C55E;
  border-radius: 4px;
  color: #101828;

  mat-icon {
    color: #22C55E;
  }
}

.scrollbar-wrapper {
  .ng-scroll-content {
    height: 100% !important;
  }
}

.toast-container {
  pointer-events: all !important;
}

.list-camera-snapshot {
  margin: 8px 0;
  height: 48px;
  width: 64px;
  max-height: 48px;
  max-width: 64px;

  img {
    max-height: 48px !important;
    max-width: 64px !important;
    height: 48px;
    width: 64px;
    border-radius: 4px;
  }

  border-radius: 4px;
  margin-right: 10px;
  background: #F9FAFB;
}

.notification-user-selection-table {
  border-radius: 4px;

  th {
    height: 44px;
  }

}

.camera-preview-data-tab, .camera-import-tab, .camera-select-tab, .pricing-info-dialog, .notification-user-selection-table {
  .mat-table {
    overflow-x: scroll;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 10px;
    line-break: unset;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }
}

.inactive {
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
  }

  &-dialog {
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    height: 230px;
    width: 475px;

    mat-icon {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
  }
}

.object-attributes-inline {
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px;
    background: var(--background-input);
  }

  .search-selector {
    width: 100%;

    .mat-mdc-form-field-wrapper {
      padding-bottom: 0px;
      background: #ffffff;
    }

    .mat-mdc-form-field-infix {
      width: auto;
    }
  }

  .operator {
    max-width: 58px !important;

    .mat-select-arrow {
      border: none;
    }
  }
}

.search-list-wrapper, .search-filters-dialog, .search-filters-dialog.property-chips {
  .ui-filter-chip {
    background-color: var(--link-active) !important;
    border-radius: 24px !important;

    .title {
      font-weight: 300;
      color: var(--color-primary-700) !important;
    }
  }
}

.menu-no-padding, .property-chips-wrapper {
  .mat-mdc-menu-panel {
    min-height: 0px !important;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &.radius {
    border-radius: 20px !important;
  }
}

.property-chips {
  padding: 10px;
  max-width: 342px;

  &.blue {
    .ui-filter-chip {
      background-color: var(--color-primary-100) !important;
      border-radius: 24px !important;

      .title {
        font-weight: 300;
        color: var(--color-primary-700) !important;
      }
    }
  }
}

.mat-mdc-menu-panel {
  overflow: hidden !important;
  min-height: 0px !important;
}

.how-to-draw {
  max-width: 400px;

  &-description {
    font-weight: 300;
    text-align: center;
  }

  &-wrapper {
    background: rgba(16, 24, 40, 0.65);
    position: absolute;
    top: 0;
    left: 0px;
    margin: 4px 0px 0px 3px;
    width: calc(100% - 6px);
    height: calc(100% - 9px);
    color: #fff;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0px;
  }
}

.no-click {
  user-select: none;
  pointer-events: none;
  cursor: none;
}

.fill img {
  object-fit: cover;
}

.mat-badge-accent .mat-badge-content {
  background-color: var(--color-red-500);
}

.bullet-divider {
  font-size: 8px;
  color: var(--color-secondary-gray-600);
}

video-progress .mat-progress-bar-buffer {
  background: #E4E8EB;
}

.c-100 {
  > div {
    width: 100%;
  }
}

.table-container {
  width: 100%;
  border-radius: 4px;
  border-bottom: none;
}

.child-100 {
  > * {
    width: 100%;
  }
}

.mat-mdc-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0px);
}

.sentence-option-selector {
  font-family: Satoshi;

  &.selected {
    .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
      color: #fff;
      border-radius: 0px;
      border-bottom: 1px solid var(--color-primary-600);
    }
  }

  .mat-option {
    font-size: 14px !important;
  }

  .mat-select-value {
    font-size: 24px;
    text-align: center;
    color: var(--color-primary-600);
    overflow: visible;
  }

  .mat-select-arrow-wrapper {
    display: none;
  }

  /* Border */
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
    color: var(--color-primary-600);
  }

  /* Font color */
  input.mat-input-element {
    color: var(--color-primary-600);
  }

  .mat-mdc-select-placeholder {
    color: var(--color-primary-600);
    font-size: 24px;
    font-weight: 500;
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 5px 4px 5px 0px
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex {
    margin-top: -0.3em;
  }

  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px;
  }

  &-panel {
    margin-top: 35px;
    overflow: hidden !important;
  }

  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    background: none !important;
  }
}


.ngx-json-viewer {
  overflow: auto;
}

.ui-selector, mat-select {
  border-radius: 4px;
  //border: 1px solid var(--input-border-default);
  background: var(--color-background-input-default);
  color: var(--color-text-subtlest);
  font-family: Satoshi !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ui-mat-icon-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;

  span {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
  }
}

.ui-mat-icon-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;

  span {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.ui-mat-icon-button-16 {
  background: var(--background-page);
  border: 1px solid var(--color-border-button-default-default);
  border-radius: 4px;
  font-size: 16px;
  width: 16px !important;
  height: 16px !important;
  padding: 8px; // figma has 8 but it has border 1 px.
  cursor: pointer;
}

.zone-selector-wrapper {
  width: 100%;
}

.create-v2-settings-form {
  .mat-mdc-form-field-label-wrapper {
    font-size: 20px !important;
  }
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-prefix, .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-suffix {
  top: 0 !important;
  opacity: 0.5 !important;
}

.toggle-menu-button {
  position: absolute;
  top: 20px;
  right: -16px;
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  height: 32px;
  width: 32px;
  z-index: 990;

  .mat-icon {
    cursor: pointer;
    line-height: 16px;
    height: 16px;
    width: 16px;
    transform: translate(-1px, -2px);

    &.collapsed {
      transform: translate(2px, -2px);
    }
  }
}

// Custom scrollbar style
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #aeaeae rgba(233, 233, 233, 0);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #e9e9e9;
}

*::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
  border-radius: 20px;
  border: 3px solid rgba(233, 233, 233, 0);
}

ng-scrollbar {
  --scrollbar-size: 10px;
}

.person-selector .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-prefix {
  opacity: 1 !important;
  top: 0.5em !important;
  margin-right: 8px;
}

.person-selector .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-suffix {
  opacity: 1 !important;
  top: 0.4em !important;
}


.mat-selector-panel-class {
  margin-top: 30px !important;
}

.icon-yellow {
  color: var(--color-yellow-500) !important;
}

.calendar-block.single {
  .mat-calendar-body-label {
    //display: none !important;
    opacity: 0 !important;
  }
}

[class^="ui-icon-"], [class*=" ui-icon-"] {
  font-size: 16px;
  color: var(--icon-default-no-bg);
}

.mat-selector-panel-class {
  background: var(--elevation-surface);
  color: var(--color-text-default);
}

.mat-icon {
  color: var(--color-icon-default);
}


.mat-flat-button.mat-primary, .mat-flat-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: var(--button-text-primary);
  background-color: var(--button-bg-primary);
}

.dark-theme .mat-ripple-element {
  opacity: 0 !important;
}

mat-mdc-form-field {
  mat-select {
    width: 100% !important;
    box-sizing: border-box;
    background: transparent;
    border: none;
  }
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
  font-size: 16px;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
  //height: 100%;
}


.mat-mdc-select-placeholder, .search-input::placeholder {
  color: var(--color-secondary-gray-500) !important;
}


.ui-input {
  color: var(--color-text-default);

  /* Text-base/Regular */
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  padding: 8px 16px;
  border-radius: 4px;

}

.ui-input:disabled {
  color: var(--color-text-disabled);
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--color-background-selected-selector-active) !important;
  border: 1px solid var(--color-border-selected-default) !important;

  &::after {
    content: url('/assets/icons/checkbox-checkmark.svg');
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // Set size if necessary
    height: 16px;
    width: 16px;
    color: var(--color-primary-white);
  }
}

.mat-checkbox.mat-primary .mat-checkbox-background {

  svg {
    display: none;
  }

}

mat-checkbox .mat-checkbox-frame {
  border-radius: 4px;
}

mat-checkbox .mat-checkbox-background {
  border-radius: 4px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-pseudo-checkbox {
  border-radius: 4px !important;
  width: 20px !important;
  height: 20px !important;


  &-checked {
    background-color: var(--color-background-selected-selector-active) !important;
    border: 1px solid var(--color-border-selected-default) !important;

    &::after {
      content: url('/assets/icons/checkbox-checkmark.svg') !important;
      display: block !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-8px, -8px) !important;
      // Set size if necessary
      height: 20px !important;
      width: 20px !important;
      color: var(--color-primary-white) !important;
      border-left: none !important;
      border-bottom: none !important;
      line-height: 0 !important;
      //margin-top: -50%;
      margin: 0 !important; //fix for last material

    }
  }
}

mat-checkbox .mat-checkbox-inner-container {
  width: 20px;
  height: 20px;
}

mat-checkbox .mat-checkbox-checkmark {
  transform: scale(0.8);
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  background: var(--color-background-input-value);
}


label {
  color: var(--color-text-default);
}

.mat-expansion-indicator::after {
  width: 4px !important;
  height: 4px !important;
  margin-top: -7px !important;
}

.custom-slider {
  .ngx-slider .ngx-slider-bar {
    background: var(--color-secondary-gray-300);
    height: 2px;
  }

  .ngx-slider .ngx-slider-selection {
    background: var(--color-primary-500);
  }

  .ngx-slider .ngx-slider-pointer {

    background-color: #ffffff;
    border: 1px solid var(--color-secondary-gray-400);
    top: -9px;
    height: 20px;
    width: 20px;

  }

  .ngx-slider-active .ngx-slider-pointer :after,
  .ngx-slider .ngx-slider-pointer:after {
    background: var(--color-primary-500);
    top: 50%;
    left: 50%;
    height: 6px;
    width: 6px;
    transform: translate(-50%, -50%);
  }

  .ngx-slider .ngx-slider-pointer:hover:after {
    background: var(--color-primary-500);
    top: 50%;
    left: 50%;
    height: 6px;
    width: 6px;
    transform: translate(-50%, -50%);
  }

  .ngx-slider .ngx-slider-bubble {
    font-size: 12px;
    bottom: 23px;
    background: var(--color-primary-500);
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;

    &:before {
      content: "▲";
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-43%, 12px) rotate(60deg);
      color: var(--color-primary-500);
    }
  }

  .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    color: var(--color-primary-500);
    display: none;
  }

  .ngx-slider .ngx-slider-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: #ffe4d1;
    top: -1px;
  }

  .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: orange;
  }

  &.precision {
    .ngx-slider {
      &:after {
        //content: ".";
        //position: absolute;
        //top: 0;
        //right: 0;
        //color: var(--color-primary-500);
        //min-height: 1px;
        //min-width: 2px;
        //background: var(--color-primary-500);
        //font-size: 6px;
        //transform: translate(0, -5px);
        //max-height: 12px;
      }

      &:before {
        //content: ".";
        //position: absolute;
        //top: 0;
        //left: 0;
        //color: var(--color-primary-500);
        //min-height: 1px;
        //min-width: 2px;
        //background: var(--color-primary-500);
        //font-size: 6px;
        //transform: translate(0, -5px);
        //max-height: 12px;
      }
    }

    .ngx-slider .ngx-slider-bubble {
      display: none;
    }

    .ngx-slider .ngx-slider-bar {
      background: var(--color-primary-500);
      height: 2px;
    }
  }
}

.input-field {
  margin-top: 16px;
}

.ui-select-option {
  color: var(--color-text-default);
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.xterm-rows {
  span {
    width: 9.031446540880504px !important;
    font-family: "Cascadia Code", Menlo, monospace !important;
  }
}

.seperator {
  height: 1px;
  background: var(--color-border-default);
  margin: 32px 0;
}

.sidebar-tooltip {

  border-radius: 4px;

  .mdc-tooltip__surface {
    color: var(--color-tooltip) !important;
    background: var(--background-tooltip) !important;
    font-size: 14px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
  }

  &.no-truncate {
    word-break: break-all !important;
    white-space: normal !important;
  }

  &.wide {

    .mdc-tooltip__surface {
      min-width: 315px !important;
    }

    width: 315px;
  }

  &.oldest-video {

    .mdc-tooltip__surface {
      min-width: 295px !important;
    }

    width: 295px;
  }

  &.schedule-info {
    .mdc-tooltip__surface {
      min-width: 265px !important;
    }

    width: 265px;
  }

  max-width: none !important;
}

/**
Material 16 has issue with options align. Solution is wrap selector by mat form field and use this class.
 */
.mat-mdc-form-field-ui-selector {
  .mat-mdc-select {
    font-size: 14px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    min-height: unset;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  mat-option {
    color: var(--color-text-default);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--input-border-default) !important;
}

.mat-checkbox-disabled {
  opacity: 0.5;
}

.mat-mdc-menu-panel {
  max-width: unset !important;
}


.step-option-selector {
  .mat-mdc-select-arrow-wrapper {
    display: none !important;
  }

}

.sentence-option-selector-panel[aria-multiselectable=false] {
  margin-top: 15px;
  margin-left: -5px;

  .mat-pseudo-checkbox {
    display: none;
  }
}

//.mat-mdc-select-placeholder, .mdc-list-item__primary-text {
//  font-size: 14px !important;
//}


.mat-mdc-text-field-wrapper {
  height: 40px !important;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 0px !important;
  font-size: 14px;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}


//material 17 issue
.mat-mdc-card-content {
  padding: 0 !important;
}

.mat-mdc-option {
  min-height: unset !important;
}
