.dialog {
  height: 100%;

  > div {
    padding: 16px 24px;
    width: 100%;
  }

  .dialog-content {
    border-top: 1px solid #EAECF0;
    border-bottom: 1px solid #EAECF0;
    height: 100%;
    max-height: calc(90vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
