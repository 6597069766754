@import "fonts";

.ui-input {
  border-radius: 4px;
  padding: 4px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text-default) !important;

  &.thick {
    padding: 8px 16px;
  }

  &.prefix {
    padding-left: 40px;
  }
}

.input-label {
  @include font(m);
}


.ui-input, .ui-input-40 {
  &.ng-invalid.ng-touched {
    border-color: red;
    outline: none;
  }
}

.ui-input-wrapper.invalid {
  .ui-input {
    border-color: red;
    outline: none;
  }

  .label {
    color: red;
  }

  .ui-input:focus-visible {
    outline-color: red;
  }

  .suffix {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
}

.validation-msg-block {
  height: 15px;
}

.ui-input-wrapper.label-out {
  .ui-input {
    border-radius: 4px;
    padding: 8px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &.narrow {
    .ui-input {
      padding: 4px 16px !important;
    }
  }

  .label {
    color: #344054;
    margin-bottom: 8px;
    @include font(mx);
  }

  .suffix {
    position: absolute;
    bottom: 10px;
    right: 15px;
    opacity: 0.7;
  }
}

// input with icon

.ui-input-container {
  position: relative;

  .input-icon-start {
    position: absolute;
    top: 12px;
    left: 16px;
    z-index: 998;
  }

  .input-icon-end {
    position: absolute;
    top: 12px;
    right: 17px;
  }

  .ui-input {
    width: 100%;
    padding-left: 48px !important;
    box-sizing: border-box;
    color: var(--color-text-default);
  }
}

.ui-input-container-no-icon {
  label {
    @include font(m);
    color: var(--color-text-default);
  }

  input, textarea {
    font-family: "Satoshi";
    @include font(xm);
    color: var(--color-text-default);
    border: 1px solid var(--color-secondary-gray-400);
    border-radius: 4px;
    padding: 7px 16px; //height should be 40 px
  }

  input.with-label {
    margin-top: 8px;
  }

  textarea.with-label {
    margin-top: 8px;

  }

  input.invalid, textarea.invalid {
    border-color: red;
    outline: none;
  }
}

.ui-input:disabled {
  opacity: 1 !important;
  color: var(--color-secondary-gray-500) !important;
  border: 1px solid var(--color-secondary-gray-400) !important;
  background: var(--link-hover) !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background: var(--link-hover);
  border-radius: 6px;
  color: var(--color-secondary-gray-600);
}

.mat-input-element:disabled {
  color: var(--color-secondary-gray-600);
}

.mat-mdc-select-arrow {
  width: 0 !important;
  height: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 solid !important;
  margin: 0 4px;
  opacity: 0;
}

.mat-mdc-select-arrow-wrapper {
  margin-top: -5px !important;
  background-image: url('/assets/icons/arrow_drop_down_big.svg');
  background-repeat: no-repeat;
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;
  transform: translateY(3px) !important;

}


.ui-selector, mat-select, .mat-mdc-select {
  border-radius: 4px;
  background: var(--color-background-input-default);
  color: var(--color-text-subtlest);
  @include font(m, true);
}

.ui-selector {
  cursor: pointer;
  border: 1px solid var(--input-border-default);

  //todo decide with Roy why we need it
  .mat-mdc-select-value {
    padding: 4px 16px;
  }

  &.thick {
    padding: 3px 0px;
  }
}


input:not([type=checkbox]), textarea {
  border: 1px solid var(--input-border-default);
  background: var(--color-background-input-default);
  color: var(--color-text-default);
}

input::placeholder, textarea::placeholder, mat-select::placeholder, .mat-mdc-select-placeholder {
  color: var(--color-secondary-gray-500) !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: var(--color-secondary-gray-600);
}

.mat-mdc-option .mdc-list-item__primary-text {
  @include font(xm, true);
  line-height: 3em !important;
  height: 3em !important;
}

.mat-pseudo-checkbox {
  border-radius: 4px !important;
  width: 20px !important;
  height: 20px !important;


  &-checked {
    background-color: var(--color-background-selected-selector-active) !important;
    border: 1px solid var(--color-border-selected-default) !important;

    &::after {
      content: url('/assets/icons/checkbox-checkmark.svg') !important;
      display: block !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-8px, -8px) !important;
      // Set size if necessary
      height: 20px !important;
      width: 20px !important;
      color: var(--color-primary-white) !important;
      border-left: none !important;
      border-bottom: none !important;
      line-height: 0 !important;
      //margin-top: -50%;

    }
  }
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
  bottom: unset !important;
  right: unset !important;
}

.mat-mdc-option .mat-icon, .mat-mdc-option .mat-pseudo-checkbox-full {
  margin-right: 8px !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: unset !important;
}

// hide checkbox for single select
.mat-mdc-select-panel[aria-multiselectable=false] {
  mat-pseudo-checkbox {
    display: none;
  }
}

.mat-mdc-option {
  min-height: unset !important;
}

.mdc-checkbox {
  padding: 0 !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px !important;
}

.mdc-checkbox__background {

  top: unset !important;
  left: unset !important;
  border-radius: 4px !important;
  width: 20px !important;
  height: 20px !important;

  svg {
    display: none;
  }
}

.mat-mdc-checkbox-checked {
  .mdc-checkbox__background {
    background-color: var(--color-background-selected-selector-active) !important;
    border: 1px solid var(--color-border-selected-default) !important;

    &::after {
      content: url('/assets/icons/checkbox-checkmark.svg') !important;
      display: block !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-8px, -8px) !important;
      // Set size if necessary
      height: 20px !important;
      width: 20px !important;
      color: var(--color-primary-white) !important;
      border-left: none !important;
      border-bottom: none !important;
      line-height: 0 !important;
      //margin-top: -50%;

    }
  }


}

.mdc-checkbox__native-control {
  width: 20px !important;
  height: 20px !important;
}

.ui-selector-40 {
  .mat-mdc-select-value {
    padding: 8px 16px !important;
  }

  border: 1px solid var(--color-secondary-gray-400) !important;
  border-radius: 4px;
}

.ui-input-40 {
  padding: 8px 16px !important;
  border-radius: 4px;
  color: var(--color-text-default);
}

.ui-phone-input-40 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}


.mat-mdc-checkbox-disabled {
  opacity: 0.5;

  label {
    color: rgba(0, 0, 0, .38) !important;
  }
}

.mat-mdc-checkbox {
  .mdc-label {
    @include font(checkbox-label);
  }
}

.bold {
  .mat-mdc-checkbox {
    .mdc-label {
      @include font(mx);
    }
  }
}

.ui-input-suffix {
  position: absolute;
  bottom: 7px;
  right: 15px;
  opacity: 0.7;
}

.ui-input-suffix.input-40 {
  bottom: 11px;
}

.ui-input-prefix {
  position: absolute;
  bottom: 8px;
  left: 8px;
  opacity: 0.7;
  font-size: 16px;
  height: 16px !important;
  width: 16px !important;
}

input[type=time] {
  color: var(--color-text-default);
  @include font(xm);

}
