@import "fonts";

.ui-modal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 16px 24px;
    @include font(m);
    color: var(--color-text-default);
    border-bottom: 1px solid var(--color-border-default);
  }

  .body {
    padding: 24px;
    border-bottom: 1px solid var(--color-border-default);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    //height: call(100% - 131); //sum of header and footer
  }

  .footer {
    padding: 20px 24px;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;

    ui-button {
      margin-left: 8px;
    }
  }
}
