@import 'src/app/theme/fonts';
@import 'src/app/theme/colors';

.ui-selector-item {
  @include font(m);
  user-select: none;
}

.ui-input-wrapper {
  width: 100%;
}

.ui-select-active-background {
  background-color: var(--color-tertiary-blue-bg-0);
}

.ui-selector-no-options-container {
  padding-bottom: 13px;
  @include font(sb);
  color: var(--color-secondary-gray-4);
}

.ui-selector-select-all {
  display: flex;
  flex-direction: row;
  margin-left: 13px;
}

.ui-selector-selected {
  display: flex;
  max-width: calc(100% - 24px);
  user-select: none;
  width: 100%;
}

.ui-selector-panel {
  //mat-dialog-container {
  //  padding: 0;
  //  min-width: 250px;
  //  border: 1px solid #e2e3e7;
  //  box-sizing: border-box;
  //  box-shadow: 0px 4px 8px rgba(108, 117, 125, 0.24);
  //  border-radius: 4px;
  //}
}

.ui-selector-scroll-bar-container {
  margin: 16px 0px;
}

.ui-selector-search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px 0px 8px 0;

  ui-search-bar {
    width: calc(100% - 12px);
  }

  .ui-input-wrapper.label-out .ui-input {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #eee;
    padding: 0px 0px 5px 0px;
    width: 100% !important;
    font-family: 'Satoshi';
  }
}

.ui-selector-menu-trigger {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;

  .arrow_icon {
    transform: rotate(180deg);
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
  }
}

.ui-selector-option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;

  &:hover {
    background-color: var(--color-secondary-gray-1);
  }
}

.ui-selector-opened {
  transform: unset;
}

.ui-selector-no-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui-selector-checkbox {
  font-size: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 8px;
}

.ui-select-multi-container {
  vertical-align: baseline;
  padding-left: 16px;

  &:hover {
    color: var(--color-primary-500);
    transition: 0.3s;
  }
}
