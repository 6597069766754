@import 'fonts';

.ui-create-page {
  overflow: hidden;

  .ui-router-wrapper {
    //height: 100%;
    overflow-y: auto;
    //max-height: calc(100vh - 131px);

    .router-content {
      //flex-grow: 1;
      //height: calc(100vh - 131px); // 72 breadcrumb + 74 header
      overflow: auto;
    }
  }
}
