/**
Panel class for custom ui-dropdown
 */

@import "fonts";

.ui-selector-backdrop {
  margin: 50px;
}

.ui-selector-panel {
  box-shadow: none !important;
  // each dropdown styling should be there
  .user-menu {

    .user-menu-inner {
      width: 224px;
      padding: 16px 19px;
      border-radius: 4px;
      border: 1px solid var(--color-border-default);
      background: var(--elevation-surface-subtlest);
    }

    .icon {
      font-size: 18px !important;
    }

    .menu-title {
      color: var(--color-secondary-gray-500);
      @include font(m);
      padding-left: 20px;
    }

    .menu-item {
      padding: 8px 16px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 4px;

      color: var(--color-text-subtle);
      @include font(m);

      .icon {
        margin-right: 8px;
        font-size: 24px;
      }

      .red-dot {
        width: 8px;
        height: 8px;
        font-size: 8px;
        color: red;
        margin: 0;
      }

      &:hover {
        background: var(--link-hover);
      }

      &.logout-button {
        .icon {
          margin-right: 10px !important;
          margin-left: 4px !important;
        }
      }
    }
  }
}
