$fonts: (
        page-title: (
                size: 24px,
                weight: 500,
                height: 32px,
        ),
        page-title2: (
                size: 36px,
                weight: 400,
                height: 44px,
        ),
        page-title3: (
                size: 18px,
                weight: 400,
                height: 28px,
        ),
        page-title4: (
                size: 24px,
                weight: 400,
                height: 32px,
        ),
        xxxs: (
                size: 6px,
                weight: 400,
                height: 16px,
        ),
        xxs: (
                size: 10px,
                weight: 400,
                height: 16px,
        ),
        xxxxs: (
                size: 10px,
                weight: 500,
                height: 16px,
        ),
        xs: (
                size: 12px,
                weight: 400,
                height: 20px,
        ),
        s: (
                size: 12px,
                weight: 500,
                height: 20px,
        ),
        xm: (
                size: 14px,
                weight: 400,
                height: 24px,
        ),
        xxm: (
                size: 14px,
                weight: 400,
                height: 20px,
        ),
        m: (
                size: 14px,
                weight: 500,
                height: 24px,
        ),
        mx: (
                size: 14px,
                weight: 500,
                height: 24px,
        ),
        l: (
                size: 16px,
                weight: 500,
                height: 24px,
        ),
        lx: (
                size: 20px,
                weight: 500,
                height: 28px,
        ),
        lxx: (
                size: 16px,
                weight: 400,
                height: 24px,
        ),
        xxl: (
                size: 64px,
                weight: 500,
                height: 64px,
        ),
        h2: (
                size: 20px,
                weight: 500,
                height: 32px,
        ),
        h4: (
                size: 15px,
                weight: 400,
                height: 24px,
        ),
        h3: (
                size: 16px,
                weight: 400,
                height: 28px,
        ),
        checkbox-label: (
                size: 12px,
                weight: 500,
                height: 20px,
        ),
        badge-label: (
                size: 12px,
                weight: 600,
                height: 24px,
        ),
);

@function is-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin font($size,$important: false) {
  $fontParams: map_get($fonts, $size);

  font-size: var(--ui-font-#{$size}-size) is-important($important);
  font-weight: var(--ui-font-#{$size}-weight) is-important($important);
  line-height: var(--ui-font-#{$size}-height) is-important($important);
}

@mixin initFonts() {
  @each $name, $props in $fonts {
    --ui-font-#{$name}-size: #{map_get($props, size)};
    --ui-font-#{$name}-weight: #{map_get($props, weight)};
    --ui-font-#{$name}-height: #{map_get($props, height)};
  }
}

