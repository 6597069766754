@import "fonts";

/**
THERE ARE MATERIAL CUSTOMIZATION STYLES.
BEFORE ADD NEW ONE PLEASE CHECK IF IT'S ALREADY EXISTS. THANKS.
 */


/**
Mat card
 */

.mat-mdc-card-content {
  padding: unset !important;
}

/**
mat menu
 */
.mat-mdc-menu-panel {
  max-width: unset !important;
}


//mat button
.mat-button, .mat-flat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  @include font(mx);
}

.mat-mdc-button > .mat-icon {
  width: 24px !important;
  height: 24px !important;
}

//tooltipps

.mdc-tooltip__surface {
  color: var(--color-tooltip) !important;
  background: var(--background-tooltip) !important;
  font-size: 14px !important;
  padding: 8px 16px !important;
}

//&.no-truncate {
//  word-break: break-all !important;
//  white-space: normal !important;
//}

//dialog shadows
div.mat-mdc-select-panel, .mat-mdc-dialog-surface {
  border-radius: 5px !important;
  //box-shadow: 0px 4px 3px -7px rgb(0 0 0 / 10%), 0px 3px 11px 3px rgb(0 0 0 / 6%), 0px 3px 17px 8px rgb(0 0 0 / 5%) !important;
  //height: auto !important; // make broken scroll of ui modal body
}

.mat-mdc-header-cell {
  @include font(xs);
}


/**
Material 16 has issue with options align. Solution is wrap selector by mat form field and use this class.
 */
.mat-form-field-ui-selector {
  .mat-mdc-select {
    font-size: 14px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    min-height: unset;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  mat-option {
    color: var(--color-text-default);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
}

/**
mat for field global border style
 */
mat-form-field {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: var(--input-border-default);
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-bottom: 0px !important;
    padding-top: 5px !important;
    min-height: unset;
  }
}


//table header font
mat-form-field.no-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-form-field-disabled {
  color: var(--color-secondary-gray-600);
  background: var(--link-hover);
}

/**
mat select global styling
 */

div.mat-mdc-select-panel {
  padding: 8px 0 !important;
  margin-top: 11px;
}

//ovveride material input inside mat form field
.mdc-text-field__input {
  padding: 4px 0 !important;
  @include font(xm, true);
}

.mdc-text-field__input.input-40 {
  padding: 8px 0 !important;
}

// mat tab
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: unset !important;
}

.mat-badge-accent .mat-badge-content {
  background-color: var(--color-red-500);
  @include font(badge-label);
  color: var(--color-primary-white);
}

.sentence-option-selector {

  .mat-mdc-select-placeholder {
    color: var(--color-text-selected) !important;
    font-size: 24px;
    font-weight: 500;
    padding: 5px 6px 5px 6px;
  }

  &.groups {
    .mat-mdc-select-placeholder {
      color: var(--color-text-default) !important;
    }
  }

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }

  .mat-mdc-select-value {
    color: var(--color-text-selected);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  .cdk-overlay-pane {
    width: 200px;
  }

  .mdc-list-item__primary-text {
    width: 100%;
  }

  .mat-mdc-option .mdc-list-item__primary-text {
    width: 100% !important;
  }
}

.step-option-selector-groups-overlay {
  min-width: 250px;
}

.step-option-selector-overlay {
  min-width: 150px;
}

.mdc-checkbox__background {
  border-color: #757575 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  background-color: var(--color-background-selected-selector-active) !important;
  border-color: var(--color-background-selected-selector-active) !important;
}

.mdc-checkbox__mixedmark {
  border-color: var(--color-elevation-surface-default) !important;
}

.mdc-checkbox__ripple {
  display: none;
}

.mat-mdc-checkbox .mdc-label {
  font-size: 14px !important;
}

.mat-mdc-unelevated-button:not(:disabled) {
  background-color: var(--color-primary-600) !important;
  color: var(--color-primary-white) !important;
}

.mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
  padding: 0px 4px 0px 16px !important;
}

.mat-mdc-select-panel, .mat-mdc-menu-panel {
  margin-top: 4px;
  box-shadow: 0px 2px 4px -2px rgba(15, 23, 42, 0.06), 0px 4px 8px -2px rgba(15, 23, 42, 0.10) !important;
  border: 1px solid var(--color-border-default);
}

.alert-type-selector {
  min-width: 170px;
}

.people-selector-appears-selector {
  min-width: 240px !important;
}

.slack-channels {
  min-width: 300px !important;
}

.widget-type-selector {
  min-width: 141px;
  margin-left: -36px;

  .mat-mdc-select-trigger {
    opacity: 0 !important;
    top: -18px !important;
  }

}

.health-status-selector, .events-selector {
  min-width: 245px;
}

.color-selection {
  .mat-mdc-form-field-flex {
    margin-top: 3px;
  }
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--color-primary-500) !important;
}

.mdc-slider__track {
  height: 2px !important;
  background: var(--color-background-neutral-default-200700) !important;

  &--inactive {
    background: var(--color-primary-500) !important;
    height: 2px !important;
  }

  &--active {
    background: var(--color-background-neutral-default-200700) !important;
    height: 2px !important;
    margin-top: 1px;

    &_fill {
      border-color: var(--color-primary-500) !important;
    }
  }

}

.mat-mdc-slider-visual-thumb .mat-ripple {
  border-radius: 50% !important;
}

.mdc-slider .mdc-slider__thumb {
  height: 20px !important;
  width: 20px !important;
  left: -10px !important;
  top: 13px !important;


  &-knob {
    background-color: var(--color-primary-white) !important;
    border: 1px solid var(--color-border-default) !important;
    position: relative;

    &:before {
      position: absolute;
      background: var(--color-primary-500);
      top: 50%;
      left: 50%;
      height: 6px;
      width: 6px;
      transform: translate(-50%, -50%);
      content: "";
      border-radius: 4px;
    }
  }
}

.mat-mdc-select {
  -webkit-font-smoothing: inherit !important;
}

.progress-wrapper {
  .mdc-linear-progress__bar-inner {
    border-color: var(--color-border-button-primary-default) !important;
  }

  .mdc-linear-progress__buffer-bar {
    background: var(--elevation-surface);
  }
}


.mat-mdc-select-disabled {
  opacity: 1 !important;
  color: var(--color-secondary-gray-500) !important;
  border: 1px solid var(--color-secondary-gray-400) !important;
  background: var(--link-hover) !important;
}

.mdc-checkbox__background {
  background-color: unset !important;
}

//.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
//  background-color: unset;
//  border-color: unset;
//}

.cdk-overlay-pane:has(.selector-option-full-width) {
  max-width: 100% !important;
  width: auto !important;
}

.mat-mdc-autocomplete-panel .mat-pseudo-checkbox {
  display: none;
}

.resolution-selector-panel {
  background: rgba(0, 0, 0, 0.7) !important;
  -webkit-backdrop-filter: blur(50px) !important;
  backdrop-filter: blur(50px) !important;
  color: var(--color-primary-white) !important;

  .mdc-list-item__primary-text {
    color: var(--color-primary-white) !important;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background: #6d6d6d;
    color: var(--color-primary-white);
  }

  border: none;

  min-width: 120px;
}
