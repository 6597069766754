:root {
  --color-primary-black: #000000;
  --color-primary-white: #ffffff;
  --color-primary-50: #f4f6fe;
  --color-primary-100: #E1E6FA;
  --color-primary-500: #4367f6;
  --color-primary-600: #3456e0;
  --color-primary-700: #2748CC;
  --color-primary-600-border: #4367f6;
  --color-secondary-gray-50: #fcfcfd;
  --color-secondary-gray-100: #f9fafb;
  --color-secondary-gray-200: #f2f4f7;
  --color-secondary-gray-300: #eaecf0;
  --color-secondary-gray-400: #d0d5dd;
  --color-secondary-gray-500: #98a2b3;
  --color-secondary-gray-600: #667085;
  --color-secondary-gray-700: #475467;
  --color-secondary-gray-800: #344054;
  --color-secondary-gray-900: #101828;
  --color-secondary-green-100: #DCFCE7;
  --color-secondary-green-500: #22C55E;
  --color-secondary-green-50: #F0FDF4;
  --color-secondary-green-200: #BBF7D0;
  --color-secondary-green-400: #4ADE80;
  --color-secondary-green-600: #16A34A;
  --color-secondary-green-700: #15803D;
  --color-secondary-green-checkmark: #22c55d;
  --color-secondary-border-shadow: #eaecf0;
  --color-secondary-border-shadow-100: #e4e4e4;
  --color-amber-500: #F59E0B;
  --color-teal-500: #14B8A6;
  --color-teal-50: #F0FDFA;
  --color-violet-500: #8B5CF6;
  --color-red-100: #FEE2E2;
  --color-red-50: #FEF2F2;
  --color-red-500: #ef4444;
  --color-red-600: #dc2626;
  --color-red-700: #B91C1C;
  --color-pink-500: #ec4899;
  --color-pink-50: #FDF2F8;
  --color-unseen: rgba(112, 181, 240, 0.2);
  --color-yellow-500: #EAB308;
  --color-border-warning: #EAB308;
  --color-icon-warning: #EAB308;
  --color-background-warning-default: #FEFCE8;
  --color-yellow-50: #FEFCE8;
  --color-blue-500: #3B82F6;
  --color-blue-50: #EFF6FF;
  --color-indigo-100: #E0E7FF;
  --color-indigo-700: #4338CA;
  --white: #FFF;
  --color-icon-accent-gray200-default: #F2F4F7;
  --color-background-selected-selector-hover: #748CED;
  --color-text-primary-default: #4367F6;
  --color-icon-success: #22C55E;
  --color-icon-info: #3B82F6;
  --color-text-primary: #4367F6;
  // old color migration from legacy_styles.scss
  --color-gray-old: #b3b7c5;
  --secondary-colors-blue-2: #627EEE;
  --color-background-accent-primary-400: #748CED;
  --color-background-accent-gray200-default: #F2F4F7;
  --color-background-accent-primary-default: #3456E0;
  --volor-background-accent-white-default: #FFF;
  --color-text-accent-primary: #4367F6;
  --color-icon-primary-subtle: #E1E6FA;
  --color-text-primary-bold: #2748CC;
  --color-background-chart-timeline-no-preview: #101828;
  --color-text-accent-green: #15803D;
  --color-background-accent-green-subtle: #BBF7D0;
}

:root {
  --elevation-surface-overlay-default: var(--color-secondary-gray-50);
  --elevation-surface-subtlest: var(--color-secondary-gray-50);
  --elevation-surface: var(--color-primary-white);
  --background-table: var(--color-secondary-gray-100);
  --background-table-header: var(--color-primary-50);
  --background-input: var(--color-secondary-gray-50);

  --link-hover: var(--color-secondary-gray-200);
  --link-active: var(--color-primary-100);
  --link-default: var(--color-primary-600);
  --icon-selected: var(--color-primary-500);
  --icon-default-no-bg: var(--color-secondary-gray-600);
  --icon-default-with-bg: var(--color-secondary-gray-600);
  --hr-default: var(--color-secondary-gray-300);

  --text-color-text-primary: var(--color-primary-600);
  --text-title: var(--color-secondary-gray-900); //todo decide wiht designer
  --button-bg-primary: var(--color-primary-500);
  --button-bg-default: var(--color-primary-white);
  --button-bg-secondary: var(--color-primary-white);
  --button-text-primary: var(--color-primary-white);
  --button-border-primary: var(--color-primary-600);
  --input-border-default: var(--color-secondary-gray-400);
  --box-shadow-default: inset 0px -1px 0px var(--color-secondary-border-shadow);
  --background-tooltip: var(--color-secondary-gray-900);
  --color-tooltip: var(--color-secondary-gray-400);
  --selected-calendar: #e7edfd;
  /**
  Final design
   */
  --color-background-selected-active: var(--color-primary-100);
  --color-icon-default: #667085;
  --color-icon-primary-default: #4367F6;
  --color-icon-subtle: #98A2B3;
  --color-background-accent-primary-subtle: #E1E6FA;
  --color-text-default: #344054;
  --color-background-button-primary-default: #4367F6;
  --color-border-button-primary-default: #3456E0;
  --color-text-inverse: #FFF;
  --color-border-button-default-default: #D0D5DD;
  --color-background-button-default-default: #FFF;
  --color-background-button-default-pressed: #F9FAFB;
  --color-elevation-surface-subtle: #F9FAFB;
  --color-border-default: #EAECF0;
  --color-text-subtle: #667085;
  --color-background-input-disabled: #F2F4F7;
  --color-background-neutral-subtlest-50900: #FCFCFD;
  --color-background-neutral-bolder: #101828;
  --color-background-accent-white-default: #FFF;
  --color-text-accent-gray800: #344054;
  --color-text-accent-gray700: #475467;
  --color-text-subtlest: #98A2B3;
  --color-background-button-video-default: rgba(16, 24, 40, 0.40);
  --color-icon-accent-gray200-default: #F2F4F7;
  --color-background-neutral-subtle-100700: #F9FAFB;
  --color-background-neutral-subtle-roy: #f3f7f8;
  --color-border-input-default: #D0D5DD;
  --color-text-accent-white: #FFFFFF;
  --color-elevation-surface-default: #FFF;
  --color-elevation-surface-overlay-darker: #F9FAFB;
  --color-background-accent-gray-200-default: #F2F4F7;
  --color-text-accent-gray-700: #475467;
  --color-elevation-surface-overlay-default: #FFF;
  --color-text-selected: #3456E0;
  --color-border-button-secondary-default: #4367F6;
  --color-elevation-surface-subtlest: #FCFCFD;
  --color-background-neutral-subtle: #F2F4F7;
  --color-text-bold: #101828;
  --color-background-input-default: #FFF;
  --color-border-input-value: #D0D5DD;
  --color-background-input-value: #FFF;
  --color-text-disabled: #D0D5DD;
  --color-background-neutral-default-200700: #F2F4F7;
  --color-link-default: #3456E0;
  --color-elevation-surface-background-default: #FFF;
  --color-border-selected-default: #3456E0;
  --color-border-accent-primary: #CFD8FA;
  --color-background-accent-primary-subtlest: #F4F6FE;
  --color-background-accent-primary-subtlest-roy: #ebeef9;
  --color-background-selected-selector-active: #4367F6;
  --color-border-button-primary-deafult: #3456E0;
  --color-background-button-secondary-default: #FFF;
  --color-border-button-tetriary-color-pressed: #E1E6FA;
  --color-background-button-tertiary-color-pressed: #F4F6FE;
  --color-background-button-tertiary-gray-hover: #F2F4F7;
  --color-background-button-tertiary-color-hover: #F4F6FE;
  --color-icon-warning: #EAB308;
  --color-background-button-secondary-pressed: #F4F6FE;
  --color-border-button-primary-pressed: #2748CC;
  --color-icon-primary-bold: #3456E0;
  --color-border-error-default: #EF4444;
  --color-border-success-default: #22C55E;
  --color-border-info: #3B82F6;
  --color-background-error-default: #FEF2F2;
  --color-background-success-default: #F0FDF4;
  --color-background-information-default: #EFF6FF;
  --color-text-error-default: #EF4444;
  --сolor-elevation-surface-subtle: #F9FAFB;
  ---color-icon-inverse: #FFFFFF;
  --color-background-accent-gray300-default: #EAECF0;
}


:root .dark-theme {
  --elevation-surface-overlay-default: #212D3F;
  --elevation-surface-subtlest: #161F31;
  --elevation-surface: var(--color-secondary-gray-900);
  --background-table: var(--color-secondary-gray-900);
  --background-table-header: #293342;
  --background-input: var(--color-secondary-gray-900);
  --link-hover: var(--color-secondary-gray-700);
  --link-active: var(--color-secondary-gray-800);
  --icon-selected: #9BACF2;
  --icon-default-no-bg: var(--color-secondary-gray-300);
  --icon-default-with-bg: var(--color-secondary-gray-300);
  --hr-default: #2D3442;
  --text-color-text-primary: #9BACF2;
  --text-title: var(--color-primary-white); //todo decide with designer
  --button-bg-primary: #9BACF2;
  --button-text-primary: var(--color-secondary-gray-900);
  --button-bg-default: transparent;
  --button-bg-secondary: var(--color-secondary-gray-900);
  --button-border-primary: var(--color-secondary-gray-800);
  --input-border-default: var(--color-secondary-gray-800);
  --box-shadow-default: 0px -1px 0px 0px rgba(255, 255, 255, 0.12) inset;
  --background-tooltip: var(--color-secondary-gray-400);
  --color-tooltip: var(--color-secondary-gray-900);
  --selected-calendar: #163483;
  /**
  Final design
   */
  --color-background-selected-active: #122FA3;
  --color-icon-default: #EAECF0;
  --color-icon-primary-default: #4367F6;
  --color-icon-subtle: #98A2B3;
  --color-background-accent-primary-subtle: #E1E6FA;
  --color-text-default: #F9FAFB;
  --color-background-button-primary-default: #748CED;
  --color-border-button-primary-default: #4367F6;
  --color-text-inverse: #101828;
  --color-border-button-default-default: #344054;
  --color-background-button-default-default: #101828;
  --color-background-button-default-pressed: #131c2e;
  --color-elevation-surface-subtle: #212D3F;
  --color-border-default: #344054;
  --color-text-subtle: #D0D5DD;
  --color-background-neutral-subtlest-50900: #101828;
  --color-background-neutral-bolder: #FCFCFD;
  --color-background-accent-white-default: #FFF;
  --color-text-accent-gray800: #344054;
  --color-text-subtlest: #98A2B3;
  --color-background-button-video-default: rgba(16, 24, 40, 0.40);
  --color-icon-accent-gray200-default: #F2F4F7;
  --color-background-neutral-subtle-100700: #344054;
  --color-border-input-default: #344054;
  --color-text-accent-white: #FFFFFF;
  --color-elevation-surface-default: #101828;
  --color-elevation-surface-overlay-darker: #161F31;
  --color-background-accent-gray-200-default: #F2F4F7;
  --color-text-accent-gray-700: #475467;
  --color-elevation-surface-overlay-default: #212D3F;
  --color-text-selected: #9BACF2;
  --color-border-button-secondary-default: #748CED;
  --color-elevation-surface-subtlest: #161F31;
  --color-background-neutral-subtle: #212D3F;
  --color-text-bold: #FFF;
  --color-background-input-default: #101828;
  --color-border-input-value: #344054;
  --color-background-input-value: #101828;
  --color-text-disabled: #667085;
  --color-background-neutral-default-200700: #F2F4F7;
  --color-link-default: #3456E0;
  --color-elevation-surface-background-default: #FFF;
  --color-border-selected-default: #3456E0;
  --color-border-accent-primary: #CFD8FA;
  --color-background-accent-primary-subtlest: #F4F6FE;
  --color-background-selected-selector-active: #4367F6;
  --color-border-button-primary-deafult: #3456E0;
  --color-background-button-secondary-default: #FFF;
  --color-border-button-tetriary-color-pressed: #E1E6FA;
  --color-background-button-tertiary-color-pressed: #F4F6FE;
  --color-background-button-tertiary-gray-hover: #F2F4F7;
  --color-background-button-tertiary-color-hover: #F4F6FE;
  --color-icon-warning: #EAB308;
  --color-background-button-secondary-pressed: #F4F6FE;
  --color-border-button-primary-pressed: #2748CC;
  --color-icon-primary-bold: #3456E0;
  --сolor-elevation-surface-subtle: #F9FAFB;
  ---color-icon-inverse: #FFFFFF;
}


