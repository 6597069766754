@font-face {
  font-family: 'lumix-icons';
  src: url('assets/fonts/lumix-icons/lumix-icons.eot?l5wxwk');
  src: url('assets/fonts/lumix-icons/lumix-icons.eot?l5wxwk#iefix') format('embedded-opentype'),
  url('assets/fonts/lumix-icons/lumix-icons.ttf?l5wxwk') format('truetype'),
  url('assets/fonts/lumix-icons/lumix-icons.woff?l5wxwk') format('woff'),
  url('assets/fonts/lumix-icons/lumix-icons.svg?l5wxwk#lumix-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ui-icon-"], [class*=" ui-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lumix-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-icon-container:before {
  content: "\ea30";
}

.ui-icon-grouped-cameras:before {
  content: "\ea2f";
}

.ui-icon-storage:before {
  content: "\ea2d";
}

.ui-icon-list-view-2:before {
  content: "\ea2e";
}

.ui-icon-star:before {
  content: "\ea2b";
}

.ui-icon-star-fill:before {
  content: "\ea2c";
  color: #eab308;
}

.ui-icon-camera-shortcut:before {
  content: "\ea2a";
}

.ui-icon-door-unlock:before {
  content: "\ea28";
}

.ui-icon-door-lock:before {
  content: "\ea29";
}

.ui-icon-exit-full-screen:before {
  content: "\ea27";
}

.ui-icon-microphone:before {
  content: "\ea26";
}

.ui-icon-youtube:before {
  content: "\ea25";
}

.ui-icon-public:before {
  content: "\ea24";
}

.ui-icon-notifications-no-stroke:before {
  content: "\ea21";
}

.ui-icon-set-rotation:before {
  content: "\ea23";
}

.ui-icon-lumana-logo:before {
  content: "\ea22";
}

.ui-icon-keyboard-arrow-right:before {
  content: "\ea20";
}

.ui-icon-event:before {
  content: "\ea1f";
}

.ui-icon-camera-control-quality:before {
  content: "\ea1b";
}

.ui-icon-camera-control-resetzoom:before {
  content: "\ea1c";
}

.ui-icon-camera-control-zoomout:before {
  content: "\ea1d";
}

.ui-icon-camera-control-zoomin:before {
  content: "\ea1e";
}

.ui-icon-map-view:before {
  content: "\ea18";
}

.ui-icon-list-view:before {
  content: "\ea19";
}

.ui-icon-floor-view:before {
  content: "\ea1a";
}

.ui-icon-shopping-cart:before {
  content: "\ea17";
}

.ui-icon-mfa:before {
  content: "\ea16";
}

.ui-icon-all-expanded:before {
  content: "\ea14";
}

.ui-icon-all-collapsed:before {
  content: "\ea15";
}

.ui-icon-pet:before {
  content: "\ea13";
}

.ui-icon-dislike:before {
  content: "\ea12";
}

.ui-icon-add-person:before {
  content: "\ea11";
}

.ui-icon-stop:before {
  content: "\ea10";
}

.ui-icon-shield:before {
  content: "\ea0f";
}

.ui-icon-phone:before {
  content: "\ea0e";
}

.ui-icon-accessibility:before {
  content: "\ea0d";
}

.ui-icon-loom:before {
  content: "\ea0c";
}

.ui-icon-pin:before {
  content: "\ea0b";
}

.ui-icon-eye_closed:before {
  content: "\e929";
}

.ui-icon-trained:before {
  content: "\ea0a";
}

.ui-icon-camera1:before {
  content: "\ea09";
}

.ui-icon-sidebar:before {
  content: "\ea08";
}

.ui-icon-live:before {
  content: "\ea07";
}

.ui-icon-globe:before {
  content: "\ea04";
}

.ui-icon-assets:before {
  content: "\ea05";
}

.ui-icon-ptz:before {
  content: "\ea06";
}

.ui-icon-billing:before {
  content: "\ea03";
}

.ui-icon-forward-3:before {
  content: "\ea02";
}

.ui-icon-hide:before {
  content: "\ea01";
}

.ui-icon-mail:before {
  content: "\ea00";
}

.ui-icon-time:before {
  content: "\e95e";
}

.ui-icon-alert-type:before {
  content: "\e9d6";
}

.ui-icon-counter:before {
  content: "\e9d5";
}

.ui-icon-pie-chart:before {
  content: "\e9cd";
}

.ui-icon-funnel-chart:before {
  content: "\e9ce";
}

.ui-icon-scatter-chart:before {
  content: "\e9cf";
}

.ui-icon-horizontal-stached-chart:before {
  content: "\e9d0";
}

.ui-icon-stacked-chart:before {
  content: "\e9d1";
}

.ui-icon-line-chart:before {
  content: "\e9d2";
}

.ui-icon-horizontal-bar-chart:before {
  content: "\e9d3";
}

.ui-icon-bar-chart:before {
  content: "\e9d4";
}

.ui-icon-acked-alert:before {
  content: "\e9cb";
}

.ui-icon-none-acked-alert:before {
  content: "\e9cc";
}

.ui-icon-login-firewall:before {
  content: "\e9ca";
}


.ui-icon-drop-down-arrow-small:before {
  content: "\e9c9";
}

.ui-icon-minus:before {
  content: "\e9c8";
}

.ui-icon-prev-frame:before {
  content: "\e9c6";
}

.ui-icon-next-frame:before {
  content: "\e9c7";
}

.ui-icon-inheritance:before {
  content: "\e9c5";
}

.ui-icon-prev-alert:before {
  content: "\e9c3";
}

.ui-icon-next-alert:before {
  content: "\e9c4";
}

.ui-icon-mobile-not-supported:before {
  content: "\e9c2";
}

.ui-icon-expand-arrow:before {
  content: "\e9c1";
}

.ui-icon-speaker:before {
  content: "\e9bd";
}

.ui-icon-mute:before {
  content: "\e9c0";
}

.ui-icon-section_arrow_left:before {
  content: "\e9bb";
}

.ui-icon-pause-new:before {
  content: "\e9bc";
}

.ui-icon-backward-10:before {
  content: "\e9be";
}

.ui-icon-forward-10:before {
  content: "\e9bf";
}

.ui-icon-download:before {
  content: "\e9ba";
  color: #667085;
}

.ui-icon-key:before {
  content: "\e9b9";
}

.ui-icon-resize-dots .path1:before {
  content: "\e9b5";
  color: rgb(242, 244, 247);
}

.ui-icon-resize-dots .path2:before {
  content: "\e9b6";
  margin-left: -2.6669921875em;
  color: rgb(102, 112, 133);
}

.ui-icon-resize-dots .path3:before {
  content: "\e9b7";
  margin-left: -2.6669921875em;
  color: rgb(102, 112, 133);
}

.ui-icon-resize-dots .path4:before {
  content: "\e9b8";
  margin-left: -2.6669921875em;
  color: rgb(102, 112, 133);
}

.ui-icon-add-alarm:before {
  content: "\e9b4";
}

.ui-icon-table-order-arrow-desc:before {
  content: "\e9b3";
}

.ui-icon-table-order-arrow-asc:before {
  content: "\e9b2";
}

.ui-icon-resend:before {
  content: "\e9b1";
}

.ui-icon-users:before {
  content: "\e9b0";
}

.ui-icon-order-arrow:before {
  content: "\e9af";
}

.ui-icon-logo:before {
  content: "\e9ae";
}

.ui-icon-cross:before {
  content: "\e9ad";
}

.ui-icon-order-filter:before {
  content: "\e9ac";
}

.ui-icon-edge-status-alert:before {
  content: "\e9aa";
}

.ui-icon-comment:before {
  content: "\e9a9";
}

.ui-icon-loading:before {
  content: "\e9a8";
}

.ui-icon-fullscreen-2:before {
  content: "\e9a7";
}

.ui-icon-lock:before {
  content: "\e987";
}

.ui-icon-tool:before {
  content: "\e986";
}

.ui-icon-add-location:before {
  content: "\e985";
}

.ui-icon-add-camera:before {
  content: "\e984";
}

.ui-icon-add-core:before {
  content: "\e983";
}

.ui-icon-device:before {
  content: "\e982";
}

.ui-icon-copy1:before {
  content: "\e964";
}

.ui-icon-copy:before {
  content: "\e962";
}

.ui-icon-api:before {
  content: "\e92d";
}

.ui-icon-terminal:before {
  content: "\e908";
}

.ui-icon-search:before {
  content: "\e903";
}

.ui-icon-notifications:before {
  content: "\e91a";
}

.ui-icon-dashboard:before {
  content: "\e921";
}

.ui-icon-wall:before {
  content: "\e94a";
}

.ui-icon-archive:before {
  content: "\e955";
}

.ui-icon-health:before {
  content: "\e956";
}

.ui-icon-favorite:before {
  content: "\e966";
}

.ui-icon-help:before {
  content: "\e96c";
}

.ui-icon-user:before {
  content: "\e96d";
}

.ui-icon-camera:before {
  content: "\e9ab";
}

.ui-icon-snapshoot:before {
  content: "\e93e";
}

.ui-icon-fullscreen:before {
  content: "\e93f";
}

.ui-icon-pause:before {
  content: "\e94b";
}

.ui-icon-play:before {
  content: "\e94d";
}

.ui-icon-player-backward:before {
  content: "\e915";

}

.ui-icon-close:before {
  content: "\e96b";

}

.ui-icon-alert .path1:before {
  content: "\e906";

}

.ui-icon-alert .path2:before {
  content: "\e909";
  margin-left: -1.0380859375em;

}

.ui-icon-alert .path3:before {
  content: "\e90a";
  margin-left: -1.0380859375em;

}

.ui-icon-alert .path4:before {
  content: "\e90b";
  margin-left: -1.0380859375em;

}

.ui-icon-alert .path5:before {
  content: "\e90c";
  margin-left: -1.0380859375em;

}

.ui-icon-alert .path6:before {
  content: "\e910";
  margin-left: -1.0380859375em;
  color: rgb(255, 255, 255);
}

.ui-icon-alert .path7:before {
  content: "\e912";
  margin-left: -1.0380859375em;
  color: rgb(0, 0, 0);
}

.ui-icon-alert .path8:before {
  content: "\e916";
  margin-left: -1.0380859375em;
  color: rgb(0, 0, 0);
}

.ui-icon-alert .path9:before {
  content: "\e917";
  margin-left: -1.0380859375em;
  color: rgb(0, 0, 0);
}

.ui-icon-archive-default .path1:before {
  content: "\e91e";

}

.ui-icon-archive-default .path2:before {
  content: "\e926";
  margin-left: -1.0146484375em;

}

.ui-icon-archive-default .path3:before {
  content: "\e928";
  margin-left: -1.0146484375em;

}

.ui-icon-archive-default .path4:before {
  content: "\e933";
  margin-left: -1.0146484375em;

}

.ui-icon-archive-default .path5:before {
  content: "\e938";
  margin-left: -1.0146484375em;

}

.ui-icon-archive-default .path6:before {
  content: "\e943";
  margin-left: -1.0146484375em;
  color: rgb(0, 0, 0);
}

.ui-icon-archive-default .path7:before {
  content: "\e944";
  margin-left: -1.0146484375em;
  color: rgb(0, 0, 0);
}

.ui-icon-archive-default .path8:before {
  content: "\e945";
  margin-left: -1.0146484375em;
  color: rgb(0, 0, 0);
}

.ui-icon-archive-default .path9:before {
  content: "\e946";
  margin-left: -1.0146484375em;
  color: rgb(0, 0, 0);
}

.ui-icon-archive-default .path10:before {
  content: "\e94e";
  margin-left: -1.0146484375em;
  color: rgb(255, 255, 255);
}

.ui-icon-archive-default .path11:before {
  content: "\e94f";
  margin-left: -1.0146484375em;
  color: rgb(213, 221, 234);
}

.ui-icon-archive-default .path12:before {
  content: "\e95c";
  margin-left: -1.0146484375em;
  color: rgb(213, 221, 234);
}

.ui-icon-archive-default .path13:before {
  content: "\e95f";
  margin-left: -1.0146484375em;
  color: rgb(213, 221, 234);
}

.ui-icon-archive-default .path14:before {
  content: "\e960";
  margin-left: -1.0146484375em;
  color: rgb(213, 221, 234);
}

.ui-icon-archive-default .path15:before {
  content: "\e968";
  margin-left: -1.0146484375em;
  color: rgb(255, 255, 255);
}

.ui-icon-archive-default .path16:before {
  content: "\e969";
  margin-left: -1.0146484375em;
  color: rgb(0, 0, 0);
}

.ui-icon-dot:before {
  content: "\e96a";

}

.ui-icon-no-wall .path1:before {
  content: "\e96e";

}

.ui-icon-no-wall .path2:before {
  content: "\e96f";
  margin-left: -1.0322265625em;
  color: rgb(245, 248, 255);
}

.ui-icon-no-wall .path3:before {
  content: "\e970";
  margin-left: -1.0322265625em;
  color: rgb(245, 248, 255);
}

.ui-icon-no-wall .path4:before {
  content: "\e971";
  margin-left: -1.0322265625em;
  color: rgb(245, 248, 255);
}

.ui-icon-no-wall .path5:before {
  content: "\e972";
  margin-left: -1.0322265625em;
  color: rgb(245, 248, 255);
}

.ui-icon-no-wall .path6:before {
  content: "\e973";
  margin-left: -1.0322265625em;
  color: rgb(0, 0, 0);
}

.ui-icon-no-wall .path7:before {
  content: "\e974";
  margin-left: -1.0322265625em;
  color: rgb(255, 255, 255);
}

.ui-icon-not-found-icon .path1:before {
  content: "\e975";
  color: rgb(228, 231, 236);
}

.ui-icon-not-found-icon .path2:before {
  content: "\e976";
  margin-left: -0.9248046875em;
  color: rgb(242, 244, 247);
}

.ui-icon-not-found-icon .path3:before {
  content: "\e977";
  margin-left: -0.9248046875em;
  color: rgb(242, 244, 247);
}

.ui-icon-not-found-icon .path4:before {
  content: "\e978";
  margin-left: -0.9248046875em;
  color: rgb(242, 244, 247);
}

.ui-icon-not-found-icon .path5:before {
  content: "\e979";
  margin-left: -0.9248046875em;
  color: rgb(242, 244, 247);
}

.ui-icon-not-found-icon .path6:before {
  content: "\e97a";
  margin-left: -0.9248046875em;
  color: rgb(242, 244, 247);
}

.ui-icon-not-found-icon .path7:before {
  content: "\e97b";
  margin-left: -0.9248046875em;
  color: rgb(249, 250, 251);
}

.ui-icon-not-found-icon .path8:before {
  content: "\e97c";
  margin-left: -0.9248046875em;
  color: rgb(0, 0, 0);
}

.ui-icon-not-found-icon .path9:before {
  content: "\e97d";
  margin-left: -0.9248046875em;
  color: rgb(0, 0, 0);
}

.ui-icon-not-found-icon .path10:before {
  content: "\e97e";
  margin-left: -0.9248046875em;
  color: rgb(242, 244, 247);
}

.ui-icon-not-found-icon .path11:before {
  content: "\e97f";
  margin-left: -0.9248046875em;
  color: rgb(228, 231, 236);
}

.ui-icon-not-found-icon .path12:before {
  content: "\e980";
  margin-left: -0.9248046875em;
  color: rgb(52, 64, 84);
  opacity: 0.4;
}

.ui-icon-people .path1:before {
  content: "\e988";

}

.ui-icon-people .path2:before {
  content: "\e989";
  margin-left: -1.298828125em;

}

.ui-icon-people .path3:before {
  content: "\e98a";
  margin-left: -1.298828125em;

}

.ui-icon-people .path4:before {
  content: "\e98b";
  margin-left: -1.298828125em;

}

.ui-icon-people .path5:before {
  content: "\e98c";
  margin-left: -1.298828125em;
  color: rgb(255, 255, 255);
}

.ui-icon-people .path6:before {
  content: "\e98d";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path7:before {
  content: "\e98e";
  margin-left: -1.298828125em;
  color: rgb(154, 162, 178);
  opacity: 0.1;
}

.ui-icon-people .path8:before {
  content: "\e98f";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path9:before {
  content: "\e990";
  margin-left: -1.298828125em;
  color: rgb(255, 255, 255);
}

.ui-icon-people .path10:before {
  content: "\e991";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path11:before {
  content: "\e992";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path12:before {
  content: "\e993";
  margin-left: -1.298828125em;
  color: rgb(57, 115, 244);
}

.ui-icon-people .path13:before {
  content: "\e994";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path14:before {
  content: "\e995";
  margin-left: -1.298828125em;
  color: rgb(57, 115, 244);
}

.ui-icon-people .path15:before {
  content: "\e996";
  margin-left: -1.298828125em;
  color: rgb(255, 255, 255);
}

.ui-icon-people .path16:before {
  content: "\e997";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path17:before {
  content: "\e998";
  margin-left: -1.298828125em;
  color: rgb(154, 162, 178);
  opacity: 0.1;
}

.ui-icon-people .path18:before {
  content: "\e999";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path19:before {
  content: "\e99a";
  margin-left: -1.298828125em;
  color: rgb(0, 0, 0);
}

.ui-icon-people .path20:before {
  content: "\e99b";
  margin-left: -1.298828125em;
  color: rgb(255, 255, 255);
}

.ui-icon-workspace-main .path1:before {
  content: "\e9d7";

}

.ui-icon-workspace-main .path2:before {
  content: "\e9d8";
  margin-left: -1.0791015625em;
  color: rgb(245, 248, 255);
}

.ui-icon-workspace-main .path3:before {
  content: "\e9d9";
  margin-left: -1.0791015625em;
  color: rgb(245, 248, 255);
}

.ui-icon-workspace-main .path4:before {
  content: "\e9da";
  margin-left: -1.0791015625em;
  color: rgb(245, 248, 255);
}

.ui-icon-workspace-main .path5:before {
  content: "\e9db";
  margin-left: -1.0791015625em;
  color: rgb(245, 248, 255);
}

.ui-icon-workspace-main .path6:before {
  content: "\e9dc";
  margin-left: -1.0791015625em;
  color: rgb(0, 0, 0);
}

.ui-icon-workspace-main .path7:before {
  content: "\e9dd";
  margin-left: -1.0791015625em;
  color: rgb(255, 255, 255);
}

.ui-icon-workspace-main .path8:before {
  content: "\e9de";
  margin-left: -1.0791015625em;

}

.ui-icon-workspace-main .path9:before {
  content: "\e9df";
  margin-left: -1.0791015625em;

}

.ui-icon-workspace-main .path10:before {
  content: "\e9e0";
  margin-left: -1.0791015625em;

}

.ui-icon-workspace-main .path11:before {
  content: "\e9e1";
  margin-left: -1.0791015625em;

}

.ui-icon-cat-custom:before {
  content: "\e9e2";

}

.ui-icon-cat-identification:before {
  content: "\e9e3";
  color: #344054;
}

.ui-icon-cat-safety:before {
  content: "\e9e4";
  color: #344054;
}

.ui-icon-cat-status:before {
  content: "\e9e5";

}

.ui-icon-cat-tracking:before {
  content: "\e9e6";

}

.ui-icon-object-analytic:before {
  content: "\e9e7";

}

.ui-icon-object-appearing:before {
  content: "\e9e8";

}

.ui-icon-object-camera:before {
  content: "\e9e9";

}

.ui-icon-object-disappearing:before {
  content: "\e9ea";

}

.ui-icon-object-edge:before {
  content: "\e9eb";

}

.ui-icon-object-face-recognition:before {
  content: "\e9ec";

}

.ui-icon-object-fire:before {
  content: "\e9ed";

}

.ui-icon-object-icon-person:before {
  content: "\e9ee";

}

.ui-icon-object-license-plate:before {
  content: "\e9ef";

}

.ui-icon-object-line-crossing:before {
  content: "\e9f0";

}

.ui-icon-object-loitering:before {
  content: "\e9f1";

}

.ui-icon-object-motion:before {
  content: "\e9f2";

}

.ui-icon-object-movement:before {
  content: "\e9f3";

}

.ui-icon-object-multiple:before {
  content: "\e9f4";

}

.ui-icon-object-occupancy:before {
  content: "\e9f5";

}

.ui-icon-object-pet:before {
  content: "\e9f6";

}

.ui-icon-object-proximity:before {
  content: "\e9f7";

}

.ui-icon-object-speed-control:before {
  content: "\e9f8";

}

.ui-icon-object-tailgating:before {
  content: "\e9f9";

}

.ui-icon-object-traffic-control:before {
  content: "\e9fa";

}

.ui-icon-object-trespassing:before {
  content: "\e9fb";

}

.ui-icon-object-vehicle:before {
  content: "\e9fc";

}

.ui-icon-object-weapon:before {
  content: "\e9fd";

}

.ui-icon-object-zone-protection:before {
  content: "\e9fe";

}

.ui-icon-tampering:before {
  content: "\e9ff";

}

.ui-icon-company:before {
  content: "\e904";

}

.ui-icon-settings:before {
  content: "\e902";

}

.ui-icon-logout:before {
  content: "\e901";

}

.ui-icon-attach_money:before {
  content: "\e900";
}

.ui-icon-addFile:before {
  content: "\e905";
}

.ui-icon-analytics:before {
  content: "\e907";
}

.ui-icon-arrow-down:before {
  content: "\e90d";

}

.ui-icon-arrow-download:before {
  content: "\e90e";

}

.ui-icon-arrow:before {
  content: "\e90f";
}

.ui-icon-backward:before {
  content: "\e911";

}

.ui-icon-bar_chart:before {
  content: "\e913";

}

.ui-icon-box:before {
  content: "\e914";

}

.ui-icon-calendar:before {
  content: "\e918";

}

.ui-icon-camera-off:before {
  content: "\e919";

}

.ui-icon-cases:before {
  content: "\e91b";
}

.ui-icon-check_in_green .path1:before {
  content: "\e91c";
  color: rgb(34, 197, 94);
}

.ui-icon-check_in_green .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ui-icon-checkmark:before {
  content: "\e91f";

}

.ui-icon-clipboard:before {
  content: "\e920";

}

.ui-icon-dashboards:before {
  content: "\e922";
}

.ui-icon-delete:before {
  content: "\e923";

}

.ui-icon-done:before {
  content: "\e924";
}

.ui-icon-drag-handle:before {
  content: "\e925";

}

.ui-icon-edit:before {
  content: "\e927";

}

.ui-icon-eye:before {
  content: "\e92a";

}

.ui-icon-folder:before {
  content: "\e92b";

}

.ui-icon-forward:before {
  content: "\e92c";

}

.ui-icon-history:before {
  content: "\e92e";

}

.ui-icon-home:before {
  content: "\e92f";

}

.ui-icon-images:before {
  content: "\e930";
}

.ui-icon-info:before {
  content: "\e931";

}

.ui-icon-layout:before {
  content: "\e932";
}

.ui-icon-link:before {
  content: "\e934";
}

.ui-icon-linkedin:before {
  content: "\e935";
}

.ui-icon-location:before {
  content: "\e936";
}

.ui-icon-locations:before {
  content: "\e937";

}

.ui-icon-monitor_heart:before {
  content: "\e939";
}

.ui-icon-more-menu:before {
  content: "\e93a";

}

.ui-icon-more:before {
  content: "\e93b";
}

.ui-icon-organization:before {
  content: "\e93c";

}

.ui-icon-picture:before {
  content: "\e93d";
}

.ui-icon-player-settings:before {
  content: "\e940";
}

.ui-icon-plus:before {
  content: "\e941";
}

.ui-icon-preferences:before {
  content: "\e942";

}

.ui-icon-reset:before {
  content: "\e947";

}

.ui-icon-save:before {
  content: "\e948";

}

.ui-icon-search_list:before {
  content: "\e949";

}

.ui-icon-expand-arrow-old:before {
  content: "\e94c";

}

.ui-icon-share-arrow:before {
  content: "\e950";

}

.ui-icon-share:before {
  content: "\e951";

}

.ui-icon-shared:before {
  content: "\e952";

}

.ui-icon-shares:before {
  content: "\e953";
}

.ui-icon-show:before {
  content: "\e954";

}

.ui-icon-starred:before {
  content: "\e957";
}

.ui-icon-stats:before {
  content: "\e958";

}

.ui-icon-status-offline:before {
  content: "\e959";
}

.ui-icon-status-online:before {
  content: "\e95a";
}

.ui-icon-status-unhealthy:before {
  content: "\e95b";
}

.ui-icon-stream:before {
  content: "\e95d";

}

.ui-icon-time:before {
  content: "\e95e";

}

.ui-icon-upload:before {
  content: "\e961";

}

.ui-icon-video-cam:before {
  content: "\e963";

}

.ui-icon-videos:before {
  content: "\e965";
}

.ui-icon-warning:before {
  content: "\e967";
}
