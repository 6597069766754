@import 'fonts';

.ui-tab {
  height: 100%;
  box-sizing: border-box;
  padding: 24px 24px;
  //max-width: 1000px;
  overflow-y: auto;

  .ui-page-title {
    @include font(page-title);
    margin-bottom: 0;
    color: var(--color-secondary-gray-900);
  }

  .ui-input-row {
    margin-bottom: 24px;
  }

  .ui-tab-inner {
    height: 100%;
  }

  &.reader {
    padding: 24px 24px;
    max-width: 100vw;
  }

  &.wide {
    max-width: 1300px;
  }

  &.limit {
    overflow: auto;
    height: calc(100% - 64px) !important;
  }
}
