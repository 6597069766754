@use '@angular/material' as mat;

@use 'sass:map';

@include mat.core();

@import 'fonts';

:root {
  @include initFonts();
}

$mat-dark-theme-foreground: (
        base: var(--color-secondary-gray-900),
        divider: var(--color-border-input-value),
        dividers: var(--color-elevation-surface-subtle),
        disabled: #EBEBE4,
        disabled-button: #EBEBE4,
        disabled-text: #EBEBE4,
        hint-text: #EBEBE4,
        secondary-text: var(--color-secondary-gray-600),
        icon: var(--color-secondary-gray-600),
        icons: rgba(black, 0.54),
        text: var(--color-text-default),
        slider-off: rgba(black, 0.26),
        slider-off-active: rgba(black, 0.38),
);

$mat-dark-theme-background: (
        status-bar: black,
        app-bar: #090D16,
        background: #303030,
        hover: var(--color-secondary-gray-700),
        card: var(--color-secondary-gray-900),
        dialog:var(--elevation-surface-overlay-default),
        disabled-button: white,
        raised-button: var(--button-bg-secondary),
        focused-button: white,
        selected-button: white,
        selected-disabled-button: white,
        disabled-button-toggle:white,
        unselected-chip:var(--color-secondary-gray-700)
);

$lumix-primary-palette: (
        50:#F4F6FE,
        100:#E1E6FA,
        200:#CFD8FA,
        300:#9BACF2,
        400:#748CED,
        500:#4367F6,
        600:#3456E0,
        700:#2748CC,
        800:#1C3BB8,
        900:#122FA3,
        contrast:(
                50:var(--color-text-default),
                100:var(--color-text-default),
                200:var(--color-text-default),
                300:#101828,
                400:var(--color-text-default),
                500:var(--color-text-default),
                600:var(--color-text-default),
                700:var(--color-text-default),
                800:var(--color-text-default),
                900:var(--color-text-default),
        )
);


$lumix-gray-palette: (
        50:var(--color-secondary-gray-50),
        100:var(--color-secondary-gray-100),
        200:var(--color-secondary-gray-200),
        300:var(--color-secondary-gray-300),
        400:var(--color-secondary-gray-400),
        500:var(--color-secondary-gray-500),
        600:var(--color-secondary-gray-600),
        700:var(--color-secondary-gray-700),
        800:var(--color-secondary-gray-800),
        900:var(--color-secondary-gray-900),
        contrast:(
                50:var(--color-text-default),
                100:var(--color-text-default),
                200:var(--color-text-default),
                300:var(--color-text-default),
                400:var(--color-text-default),
                500:var(--color-text-default),
                600:var(--color-text-default),
                700:var(--color-text-default),
                800:var(--color-text-default),
                900:var(--color-text-default),
        )
);

$my-primary: mat.define-palette($lumix-primary-palette, 300);
$my-accent: mat.define-palette($lumix-gray-palette);

$dark-theme: mat.define-dark-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        ),

));
$dark-theme: map.set(
                $dark-theme,
                color,
                foreground,
                $mat-dark-theme-foreground
);

$dark-theme: map.set(
                $dark-theme,
                color,
                background,
                $mat-dark-theme-background
);


.dark-theme {
  @include mat.all-component-themes($dark-theme);
  //@include mat.form-field($dark-theme);
}


$mat-light-theme-foreground: (
        base: var(--color-text-default),
        divider: var(--color-border-input-value),
        dividers: var(--color-elevation-surface-subtle),
        disabled: #EBEBE4,
        disabled-button: #EBEBE4,
        disabled-text: #EBEBE4,
        hint-text: #EBEBE4,
        secondary-text: var(--color-secondary-gray-600),
        icon: var(--color-secondary-gray-600),
        icons: rgba(black, 0.54),
        text: var(--color-text-default),
        slider-off: rgba(black, 0.26),
        slider-off-active: rgba(black, 0.38),
);


$mat-light-theme-background: (
        status-bar: black,
        app-bar: #090D16,
        background: var(--color-primary-white),
        hover: rgba(0, 0, 0, .04),
        card: var(--color-primary-white),
        dialog:var(--elevation-surface-overlay-default),
        disabled-button: white,
        raised-button: var(--button-bg-secondary),
        focused-button: white,
        selected-button: white,
        selected-disabled-button: white,
        disabled-button-toggle:white,
        unselected-chip:var(--color-secondary-gray-700)
);


// Light theme

$light-theme: mat.define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        )
));


$light-theme: map.set(
                $light-theme,
                color,
                foreground,
                $mat-light-theme-foreground
);

$light-theme: map.set(
                $light-theme,
                color,
                background,
                $mat-light-theme-background
);


.light-theme {
  @include mat.all-component-themes($light-theme);
}

