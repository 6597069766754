@use '@angular/material' as mat;

@import "fonts";

@font-face {
  font-family: 'Satoshi';
  font-weight: 100;
  src: local('Satoshi'), url(./assets/fonts/Satoshi/Satoshi-Light.ttf) format('truetype');
}


@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./assets/fonts/Satoshi/Satoshi-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: 600;
  src: local('Satoshi'), url(./assets/fonts/Satoshi/Satoshi-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  font-weight: bold;
  src: local('Satoshi'), url(./assets/fonts/Satoshi/Satoshi-Bold.ttf) format('truetype');
}


@font-face {
  font-family: 'Satoshi';
  font-weight: 800;
  src: local('Satoshi'), url(./assets/fonts/Satoshi/Satoshi-Black.ttf) format('truetype');
}


*:not(.material-icons, .material-icons-outlined, .material-icons-round, .material-icons-sharp, .material-icons-two-tone, .mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-button-wrapper) {
  font-family: "Satoshi" !important;
}

html,
body {
  height: 100%;
  font-family: 'Satoshi' !important;
  color: var(--color-text-default);
  @include font(xxm);
}

body {
  margin: 0;
  font-family: 'Satoshi' !important;
  overflow: hidden;
}

hr {
  height: 1px;
  background: var(--hr-default);
  margin: 8px 0;
  border: 0;
}

label {
  color: var(--color-text-default);
  @include font(xxm);
}

h1 {
  margin: 0;
}

h4 {
  @include font(h4);
  margin: 0 0 16px; //legacy
}

h3 {
  @include font(h3);
  margin: 0 0 16px; //legacy
}

h2 {
  @include font(h2);
  margin: 0 0 16px; //legacy
}

//todo same as hidden.

.display-none {
  display: none !important;
}

.invisible {
  opacity: 0;
  max-height: 0;
  height: 0;
  transition: 0.3s;
}

.hover {
  opacity: 0;
  transition: 0.3s;
}


/**
todo need to check
 */
.page-wrapper {
  height: calc(100%);
  width: 100%;

  > div {
    width: 100%;
    height: 100%;
  }

  .inner {
    //max-width: 1820px;
    padding: 25px 30px;
    overflow-y: auto;
    max-height: calc(100vh - 75px);
  }
}


/**
LEGACY
 */

.seperator {
  height: 1px;
  background: var(--color-border-default);
  margin: 32px 0;
}


@media only screen and (min-width: 2000px) {
  .mw {
    max-width: 1400px !important;
  }

  .mw-m {
    max-width: 1600px !important;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
  .mw {
    max-width: 1500px !important;
  }

  .mw-m {
    max-width: 1500px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .mw {
    max-width: 1100px !important;
  }

  .mw-m {
    max-width: 1300px !important;
  }
}

@media only screen and (max-height: 900px) {
  .mw {
    max-width: 1500px !important;
  }

  .mw-m {
    max-width: 1500px !important;
  }

  .ui-selector-panel {
    mat-dialog-container {
      //transform: scale(0.9) !important;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
      box-shadow: 0px 4px 8px -2px rgba(15, 23, 42, 0.10), 0px 2px 4px -2px rgba(15, 23, 42, 0.06);
    }
  }
}


table {
  border-spacing: 0;
  width: 100%;
}


//legacy

.footer-actions {
  height: 70px;
  max-height: 70px;
  padding: 15px 28px;
  background: var(--elevation-surface);
  border-top: 1px solid var(--color-border-default);
}

.mat-select-panel {
  max-height: 355px !important;
}

.c-100 {
  > div {
    width: 100%;
  }
}

.top-left-actions {
  z-index: 999;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0px 8px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  color: #ffffff;
  border-radius: 5px;
  min-height: 32px;

  mat-icon {
    color: #ffffff;
    font-size: 30px;
    height: 30px;
    width: 30px;
    line-height: 40px;
  }

  .maximize-button {
    cursor: pointer;

    mat-icon {
      color: #ffffff;
      color: #ffffff;
      font-size: 35px;
      height: 35px;
      width: 30px;
      line-height: 43px;
    }
  }

  .divider {
    height: 28px;
    width: 2px;
    background: #d5dbee;
    margin: 0px 0px 0px 5px;
    opacity: 0.1;
  }
}

.hover {
  opacity: 0;
  transition: 0.3s;
}

.p-wrapper:hover .hover,
.animation-wrapper:hover .hover,
.playback-wrapper:hover .hover,
.camera-view-wrapper:hover .hover {
  opacity: 1;
  transition: 0.3s;
}

.camera-player-wrapper {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 5px;
  position: relative;
  background: #000000;
  width: 100%;

  &:hover {
    .camera-change-label {
      opacity: 1;
    }
  }

  .camera-change-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 999;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    padding: 10px 15px;
    opacity: 0;
    transition: 0.3s;
  }

  stream-player .p-wrapper .video-wrapper, webrtc-player .p-wrapper .video-wrapper {
    background: #000;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  stream-player .p-wrapper .video-wrapper video, webrtc-player .p-wrapper .video-wrapper video {
    border-radius: 0px;
    display: block;
    height: 100%;
    width: 100%;
  }
}

.playback-wrapper,
.camera-view-wrapper {
  stream-player .p-wrapper .video-wrapper, webrtc-player .p-wrapper .video-wrapper {
    background: #000;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 4px 4px 4px 4px;
  }

  stream-player .p-wrapper .video-wrapper video, webrtc-player .p-wrapper .video-wrapper video {
    border-radius: 4px 4px 4px 4px;
    height: 100%;
    width: 100%;
    background: #000;
  }
}

.no-camera-wrapper {
  border: 1px dashed var(--color-primary-500);
  border-radius: 5px;
}


.bar {
  opacity: 0.5;
  transition: 0.3s;
}

.bar:hover {
  opacity: 1;
}

.bar-label {
  opacity: 0.4;
  font-size: 12px;
  width: 100%;
  margin-top: 10px;
}

.bar-tooltip {
  position: absolute;
  z-index: 999 !important;
  top: -78px;
  background: #fff;
  border-radius: 8px;
  padding: 11px 15px;
  transform: translateX(-50%);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.15), 0 8px 10px 1px rgba(0, 0, 0, 0.11), 0 3px 14px 2px rgba(0, 0, 0, 0.09);
  min-width: 320px;

  &-time {
    font-size: 12px;
    margin-top: 5px;
    opacity: 0.5;
  }

  &-status {
    font-size: 16px;

    &.red {
      color: #f24949;
    }
  }
}

.landscape {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.portrait {
  width: auto;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.square {
  width: 100%;
  height: 100%;
}

.hover-parent:hover {
  .hover {
    opacity: 1;
  }
}

.ck-editor__editable_inline {
  min-height: 250px;
  border: 1px solid var(--color-border-default) !important;
  margin-top: 8px;
  box-shadow: none !important;
}

.ck-toolbar {
  border: 1px solid var(--color-border-default) !important;
}

.ck-content {
  font-size: 16px;
}

.icon-hover {
  padding: 8px;
  border: 1px solid transparent;
}

.icon-hover:hover {
  border-radius: 4px;
  background: var(--color-background-button-tertiary-gray-hover);
  cursor: pointer;
}

.ui-static-icon-button-42 {
  background: var(--background-page);
  border: 1px solid var(--color-border-button-default-default);
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
}

.ui-static-icon-button-32 {
  background: var(--background-page);
  border: 1px solid var(--color-border-button-default-default);
  border-radius: 4px;
  font-size: 16px !important;
  padding: 8px;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

//for mobile phone input
.iti {
  width: 100%;
}


// for tabs in entire projects
.tabs {
  border-bottom: 1px solid var(--color-border-default);

  .tab {
    padding: 16px 0;
    color: var(--color-text-subtle);
    @include font(mx);
    margin-right: 24px;

    .count-badge {
      margin-left: 12px;
    }
  }

  .tab.active {
    color: var(--color-text-selected);
    border-bottom: 3px solid var(--color-border-button-secondary-default);
  }


}

.toast-bottom-center-custom {
  bottom: 0;
  left: calc(50% - 200px);
  width: 400px;
}

.flex-table {
  font-size: 14px;

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border: 1px solid var(--color-border-default);
    border-radius: 4px;
    color: var(--color-text-default);
  }

  &-header {
    position: sticky;
    width: 100%;
    top: 0;
    color: var(--color-text-subtle);
    background: var(--color-elevation-surface-subtle);

    border-bottom: 1px solid var(--color-border-default);
    padding: 8px 0px;
    text-align: left;


    > div {
      font-size: 12px;
      flex: 1 1 0%;
      box-sizing: border-box;

      &:first-child {
        text-align: left;
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }

  &-row {
    width: 100%;
    color: var(--color-text-default);
    padding: 8px 0px;
    text-align: left;

    > div {
      font-size: 14px;
      flex: 1 1 0%;
      box-sizing: border-box;

      &:first-child {
        text-align: left;
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }
}

.dialog {
  height: 100%;

  .dialog-content {
    border-top: 1px solid #EAECF0;
    border-bottom: 1px solid #EAECF0;
    height: 100%;
    max-height: calc(90vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;

  }
}

.horizontal-divider {
  background: var(--color-border-default);
  width: 1px;
  height: 100%;
  margin: 8px 12px;
}

.vertical-divider {
  background: var(--color-border-default);
  width: 100%;
  height: 1px;
  margin: 12px 8px;
}


.cdk-overlay-container:has(.z-index-1001) {
  z-index: 1001 !important;
}

.multi-line-tooltip {
  white-space: pre-line;
  text-align: left; /* Optional: Align the text to the left */
}

/* Hide the default close button in the InfoWindow */
.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw-ch {
  padding: 0 !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw-tc {
  display: none !important;
}

.needle-custom-marker {
  transform-origin: top;

  .needle {
    height: 40px;
    width: 1px;
    background: black;
  }
}


.cdk-virtual-scroll-content-wrapper {
  min-height: 100%;
}

/**
Needs for wap ui preloader to dont resize page when content is finished loaded but loader is not appear.
 */
.preloader-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
