[class^="ui-icon-"], [class*=" ui-icon-"] {
  font-size: 16px;
  color: var(--icon-default-no-bg);
}

.ui-mat-icon-button-16 {
  background: var(--background-page);
  border: 1px solid var(--color-border-button-default-default);
  border-radius: 4px;
  font-size: 16px;
  width: 16px !important;
  height: 16px !important;
  padding: 8px; // figma has 8 but it has border 1 px.
  cursor: pointer;
}

.ui-icon-20 {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
  font-size: 20px !important;
}

.ui-icon-16 {
  height: 16px !important;
  width: 16px !important;
  line-height: 16px !important;
  font-size: 16px !important;
}


//todo refactor from mat-icon to ui-icons


.icon-16 {
  .mat-icon {
    height: 16px !important;
    width: 16px !important;
    line-height: 16px !important;
    font-size: 16px !important;
  }
}

.icon-14 {
  .mat-icon {
    height: 14px !important;
    width: 14px !important;
    line-height: 14px !important;
    font-size: 14px !important;
  }
}


.icon-20 {
  .mat-icon {
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
    font-size: 20px !important;
  }
}

.icon-18 {
  .mat-icon {
    height: 18px !important;
    width: 18px !important;
    line-height: 18px !important;
    font-size: 18px !important;
  }
}

.ui-icon-button {
  border: 1px solid var(--color-border-button-default-default);
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;

  &.primary, &.selected {
    border: 1px solid var(--color-border-button-primary-deafult);
    color: var(--color-icon-primary-default);
  }

  &.primary-full {
    background: var(--color-background-button-primary-default);
    color: var(--color-primary-white);
    border: 1px solid var(--color-border-button-primary-deafult);

    &:hover {
      background: var(--color-border-button-primary-pressed);
    }
  }

  &:hover {
    background: var(--color-background-button-tertiary-gray-hover);
  }

  &.no-border {
    border: none;
    padding: 9px;

    &.primary, &.selected {
      border: none;
    }
  }
}

.ui-preloader-button {
  border: 1px solid var(--color-border-button-default-default);
  border-radius: 4px;
  padding: 8px 4px;
  cursor: pointer;
  min-width: 24px;

  &.primary {
    border: 1px solid var(--color-border-button-primary-deafult);
    color: var(--color-icon-primary-default);
  }
}

.ui-icon-status-offline {
  color: var(--color-red-600) !important;
}

.ui-icon-status-online {
  color: var(--color-icon-success) !important;
}
