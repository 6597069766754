//gridster {
//  background-color: #ffffff !important;
//}
//
//gridster .gridster-row {
//  background-color: var(--color-background-accent-primary-subtlest) !important;
//}
//
//gridster .gridster-column {
//  background-color: var(--color-background-accent-primary-subtlest) !important;
//}
//
//.gridster-preview {
//  border-radius: 4px !important
//}

gridster {
  background-color: var(--color-background-accent-primary-subtlest) !important;
}

gridster-item {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--color-border-default);
}

gridster .gridster-row {
  border-top: none !important;
  border-bottom: none !important;
  outline: 6px solid #fff;
  outline-offset: 0px;
}

gridster .gridster-column {
  border-left: none !important;
  border-right: none !important;
  outline: 6px solid #fff;
  outline-offset: 0px;
}
